import styled from "styled-components";
import { colorSecundario } from "../UI/variables";
import iconoAdicionar from "../../assets/iconos/Agregar.svg";

const StyledTarjeta = styled.div`
  width: 328px;
  height: 85px;
  background-color: ${colorSecundario};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 8px 0px;
`;

const Icono = styled.img`
  width: 40px;
  height: 40px;
`;
const Texto = styled.p`
  margin: 0;
  font-weight: 500;
`;

const AddTarjetaPropiedad = () => {
  return (
    <StyledTarjeta>
      <Icono src={iconoAdicionar} alt="Agregar propiedad"/>
      <Texto>Agregar una propiedad</Texto>
    </StyledTarjeta>
  );
};

export default AddTarjetaPropiedad;
