import styled from "styled-components";
import React, { useEffect, useState } from 'react'

import { colorPrimario} from "../UI/variables";

const StyledSection=styled.section`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 328px;
  height: 100%;
  display: flex;
  justify-content: space-between ;
`
const DivEncabezadoCard = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: #f56767;
  p{
    display: flex;
    width: 100%;
    color: white;
    margin: 0;
    padding-bottom: 16px;
    align-items: center;
    justify-content: center;
    font-weight: bold;  
    font-size: 16px;
    text-align: center;
  }
`
const PCelular = styled.p`
  color: blue;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  padding: 0;
  text-align: center;
`
const StyledDiv=styled.div`
  display: flex;
`
const DivContenedorPropietario=styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 163px;
  justify-content: space-between;
  border: 1px solid ${colorPrimario};
  margin: 0 2px 16px 0;
  p{
    margin: 0 0 0 8px;
    padding: 4px;
  }
`

const DivContenedor=styled.div`
  display: flex;
  flex-direction: column;
  width: 163px;
  justify-content: space-between;
  border: 1px solid ${colorPrimario};
  margin: 0 2px 16px 0;

  p{
    margin: 0 0 0 8px;
    font-size: 14px;
    padding: 4px;
  }
`
const HabitacionVencida=(props)=>{
  return(
    <StyledSection>
      <StyledDiv>
        <DivContenedorPropietario>
          <DivEncabezadoCard>
            <p>{`${props.nombrePropietario} ${props.apellidoPropietario}`}</p>
          </DivEncabezadoCard>
          <PCelular>{`Cel: ${props.celularPropietario}`}</PCelular>
          <p><strong>DNI:</strong>{` ${props.dniPropietario}`}</p>
        </DivContenedorPropietario>

        <DivContenedor>
          <DivEncabezadoCard>
            <p>{`Habitacion ${props.nroHabitacion}`}</p>
          </DivEncabezadoCard>
          <p><strong>Estudiante:</strong>{` ${props.estudiante}`}</p>
          <p><strong>Celular:</strong>{` ${props.celularEstudiante}`}</p>
        </DivContenedor>
      </StyledDiv>
    </StyledSection>
  )
}
export default HabitacionVencida