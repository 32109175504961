import styled from "styled-components";
import { textoOscuro } from "./UI/variables";

const Entrada = styled.input`
  display: block;
  width: 306px;
  height: 12px;
  border-radius: 5px;
  padding: 10px;
  border-width: 1px;
  margin-bottom: 8px;
  border-color: ${textoOscuro};
  color: blue;
  font-weight: 500;
`;

export default Entrada;
