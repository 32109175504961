import React from 'react'
import ListaPropietarios from '../../../components/ListaPropietarios'
import styled from 'styled-components'
import Boton from '../../../components/Boton'
import Titulo2 from '../../../components/Titulo2'
import { fondo } from '../../../components/UI/variables'

const StyledSection = styled.section`
  background-color: ${fondo};
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  height: 40px;
  width: 100%;
  align-items: center;
`
const TituloStyled= styled(Titulo2)`
  width: 100%;
  text-align: center;
`
const StyledDiv = styled.div`
  padding-top: 32px;
  width: 100%;
`
const GestionarSuscripciones = () => {
  return (
    <StyledSection>
      <StyledHeader>
        <Boton to={`/HomeAdmin`}>Atras</Boton>
        <TituloStyled $naranja> Gestion de Suscripciones</TituloStyled>
      </StyledHeader>
      <StyledDiv>
        <ListaPropietarios/>
      </StyledDiv>
    </StyledSection>
  )
}

export default GestionarSuscripciones