import React from 'react'
import styled from 'styled-components'
import GraficoBarraHorizontal from '../GraficoBarraHorizontal';

const StyledSection = styled.section`
  padding: 0;
  display: flex;
  justify-content: space-around;
  height: 112px;
  align-items: center;
  width: 360px;
`
const StyledDivVertical = styled.div`
  display: flex;
  margin-right: 8px;
`
const StyledP = styled.section`
  display: flex;
  align-items: center;
  color: ${(props)=>props.color};
  width: 24px;
  height: auto;
  text-align: center;
  justify-content: center;
  font-weight: bold;
  transform: rotate(-90deg);
  margin: 0 0 0 0 ;
`
const StyledDiv = styled.div`
  width: 100%;
`
const StyledPNombre = styled.p`
  margin: 0 0 8px 0;
  font-weight: 400;
`
const StyledPCorreo = styled.p`
  margin: 0 0 8px 0;
  /* font-weight: 500; */
  color: blue;
`
const DivInicioFin = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  p{
    height: 16px;
    margin: 0;
    text-align: center;
    font-size: 12px;
  }
`
const StyledDivEstado = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props)=>props.color};
  color: white;
  font-weight: bold;
  width: 88px;
  margin: 0 0 0 8px;
  height: 112px;
`
const CardPropietarioBloquear = (props) => {
  // Calcula la diferencia en días entre dos fechas
  const calcularDiferenciaEnDias = (fechaInicio, fechaFin) => {
    const unDiaEnMilisegundos = 1000 * 60 * 60 * 24;
    const diferenciaEnMilisegundos = fechaFin - fechaInicio;
    return Math.ceil(diferenciaEnMilisegundos / unDiaEnMilisegundos);
  };
  //**************************************FUNCION PARA SUMAR DIAS*************************************** */
  const sumarDias = (fecha, dias) => {
    const fechaSumada = new Date(fecha);
    fechaSumada.setDate(fechaSumada.getDate() + dias);
    return fechaSumada;
  }
  const hoy = sumarDias(new Date(),0)
  return (
    <StyledSection>
      <StyledDivVertical>
        <StyledP>{`${props.dni}`}</StyledP>
        {props.suscripcionActiva==true ? <StyledP color="green">Activo</StyledP> : <StyledP color="red">Inactivo</StyledP>}
      </StyledDivVertical>
      <StyledDiv>
        <StyledPNombre>{`${props.nombre} ${props.apellido}`}</StyledPNombre>
        <StyledPCorreo>{`${props.correo}`}</StyledPCorreo>
        {props.suscripcionActiva 
          ?
          <div>
            <GraficoBarraHorizontal limiteDias={props.diasTotalAcumulados} diasTranscurridos={calcularDiferenciaEnDias (props.fechaRenovacionSuscripcion.toDate(), hoy)} />
            <DivInicioFin>
              <p>Inicio</p>
              <p>Fin</p>
            </DivInicioFin>
          </div>
          :
          <></>
        }
      </StyledDiv>
      {props.bloqueado ? <StyledDivEstado color='red'>Blocked</StyledDivEstado> : <StyledDivEstado color='green'>Unblocked</StyledDivEstado>}
    </StyledSection>
  )
}

export default CardPropietarioBloquear