import React, { useState } from "react";
import styled from "styled-components";
import { colorSecundario } from "../UI/variables";
import BotonSubmit from "../BotonSubmit";
import appFirebase from "../../firebase";
import { getFirestore, updateDoc, doc } from "firebase/firestore";

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  background-color: white;
  background: ${colorSecundario};
  width: 360px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;
const DivEncabezadoCard = styled.div`
  display: flex;
  background-color: black;
  height: 80px;
  justify-content: center;
  align-items: center;
  p {
    color: white;
    margin: 8px 0 8px 0;
    font-weight: bold;
  }
`;
const DivDni = styled.div`
  width: 40%;
  padding: 0 8px 0 8px;
`;
const DivNombre = styled.div`
  width: 60%;
  padding: 0 8px 0 8px;
`;
const DivTarjeta = styled.div`
  width: 100%;
  height: 160px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 8px;
`;
const StyledForm = styled.form`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-around;
`;
const StyledText = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  justify-content: space-between;
  margin: 0px 8px 0px 8px;
  width: 70%;
  height: auto;
  p {
    margin: 8px 0 0 0;
  }
  label {
    height: 24px;
    margin: 8px 0 8px 0;
    width: 100%;
    height: 96px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-weight: bold;
  }
  input {
    margin: 0 0 0 32px;
    padding: 0px 0px;
    width: 32px;
    height: 24px;
    text-align: center;
  }
`;
const StyeledDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;
const StyledTextarea = styled.textarea`
  width: 100%;
  height: 96px;
  resize: none;
  padding: 8px;
  margin: 0;
`;
const StyledDivMotivo=styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 96px;
`;
const BotonBloquear = styled(BotonSubmit)`
  display: flex;
  flex-direction: column;
  width: 30%;
  height: auto;
  margin: 0;
  border-radius: 8;
`;
const BotonDesbloquear = styled(BotonSubmit)`
  display: flex;
  flex-direction: column;
  width: 30%;
  height: auto;
  margin: 0;
  border-radius: 8;
  background-color: gray;
`;
const PropietarioBloquear = ({propiedadesOwner, idUsuario, fechaRenovacionSuscripcion, diasTotalAcumulados, nombre, apellido, dni, celular, correo, bloqueado, motivoBloqueo}) => {
  const db = getFirestore(appFirebase);
  const [procesoEnvioIniciado, setProcesoEnvioIniciado] = useState(false);
  const [motivoBloqueoInput, setMotivoBloqueoInput] = useState("");

  const manejarMotivoBloqueoInput = (e) => {
    setMotivoBloqueoInput(e.target.value);
  };

  const idsPropiedadesPropietario = [];
  propiedadesOwner.map((propiedadOwner) => {
    if (propiedadOwner.idPropietario == idUsuario) {
      idsPropiedadesPropietario.push(propiedadOwner.idPropiedad);
    }
  });
  //**************************************FUNCION PARA SUMAR DIAS*************************************** */
  const sumarDias = (fecha, dias) => {
    const fechaSumada = new Date(fecha);
    fechaSumada.setDate(fechaSumada.getDate() + dias);
    return fechaSumada;
  }
  const fechaVencimientoSuscripcion = fechaRenovacionSuscripcion&&sumarDias(fechaRenovacionSuscripcion.toDate(),diasTotalAcumulados).toLocaleDateString();

  //*************************FUNCION DESACTIVAR SUSCRIPCION PROPIEDAD******************** */
  const desactivarSuscripcionPropiedades = () => {
    idsPropiedadesPropietario.forEach(async (id) => {
      const datosRegistro = {
        suscripcionActiva: false,
      };
      try {
        const documentoRef = doc(db, "propiedades", id);
        await updateDoc(documentoRef, datosRegistro);
      } catch (error) {
        console.error("Error al activar suscripcion propiedad", error);
      }
    });
  };
  //**************************FUNCION PARA BLOQUEAR Y DESBLOQUEAR USUARIO********************* */
  const alternarEstadoBloqueoUsuario = async () => {
    setProcesoEnvioIniciado(true);
    let datosEnviar;
    if (bloqueado == false) {
      datosEnviar = {
        suscripcionActiva: false,
        bloqueado: true,
        motivoBloqueo: motivoBloqueoInput,
      };
    } else {
      datosEnviar = {
        bloqueado: false,
        motivoBloqueo: "",
      };
    }
    try {
      const documentoRef = doc(db, "usuarios", idUsuario);
      await updateDoc(documentoRef, datosEnviar);
    } catch (error) {
      console.error("Error al bloquear propietario", error);
    }
    setProcesoEnvioIniciado(false);
  };
  //********************************FUNCION PARA MANEJAR ENVIO DE FORMULARIO*************************** */
  const manejarSubmit = (event) => {
    event.preventDefault();
    if(bloqueado==false){
      if (window.confirm(`¿Estas seguro de bloquear al propietario ${nombre} ${apellido}?`)){
        alternarEstadoBloqueoUsuario();
        desactivarSuscripcionPropiedades();
      }
    }else{
      if (window.confirm(`¿Estas seguro de desbloquear al propietario ${nombre} ${apellido}?`)){
        alternarEstadoBloqueoUsuario();
      }
    }
  };
  return (
    <StyledSection>
      <DivEncabezadoCard>
        <DivDni>
          <p>DNI: {`${dni}`}</p>
          <p>Cel: {`${celular}`}</p>
        </DivDni>
        <DivNombre>
          <p>{`${nombre} ${apellido}`}</p>
          <p>{`${correo}`}</p>
        </DivNombre>
      </DivEncabezadoCard>

      <DivTarjeta>
        <StyledForm onSubmit={manejarSubmit}>
          <StyledText>
            <StyeledDiv>
              <p><strong>Vence:</strong></p>
              <p>{`${fechaRenovacionSuscripcion ? fechaVencimientoSuscripcion: ""}`}</p>
            </StyeledDiv>

            {bloqueado == false ? (
              <label>
                Motivo bloqueo:
                <StyledTextarea
                  type="texto"
                  value={motivoBloqueoInput}
                  onChange={manejarMotivoBloqueoInput}
                  required
                />
              </label>
            ) : (
              <StyledDivMotivo>
                <p><strong>Motivo bloqueo:</strong></p>
                <p>{`${motivoBloqueo}`}</p>
              </StyledDivMotivo>
            )}

          </StyledText>
          {bloqueado == false ? (
            <BotonBloquear $procesoenvioiniciado={procesoEnvioIniciado} disabled={procesoEnvioIniciado ? true : false}>Bloquear</BotonBloquear>
          ) : (
            <BotonDesbloquear $procesoenvioiniciado={procesoEnvioIniciado} disabled={procesoEnvioIniciado ? true : false}>Desbloquear</BotonDesbloquear>
          )}
        </StyledForm>
      </DivTarjeta>
    </StyledSection>
  );
};

export default PropietarioBloquear;