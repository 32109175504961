import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContextStudent } from "../context/AuthStudent";

const PrivateRouteStudent = () => {
  const { currentUser } = useContext(AuthContextStudent);

  return !!currentUser ? <Outlet /> : <Navigate to="/SignIn" />;
};

export default PrivateRouteStudent;
