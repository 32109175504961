import React, { createContext, useEffect, useState } from "react";
import appFirebase from "../firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, onSnapshot, collection, query, where } from "firebase/firestore";
import { useAuth } from "./AuthContext";

const auth = getAuth(appFirebase);
const db = getFirestore(appFirebase);

export const AuthContextOwner = createContext();
export const AuthProviderOwner = ({ children }) => {

  const { userData, usuarioActual } = useAuth();
  const [ownerData, setOwnerData] = useState(null);
  const [propiedadesOwner, setPropiedadesOwner] = useState([]);
  const [habitacionesOwner, setHabitacionesOwner] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);

  //*****************************OBTENCION DE DATOS DEL USUARIO LOGUEADO****************************** */
  useEffect(() => {
    setOwnerData({
      uid: usuarioActual.uid,
      correo: usuarioActual.email,
      rol: userData.rol,
      celular: userData.celular,
      dni: userData.dni,
      ubicacion: userData.ubicacion,
      uuid: userData.uuid,
      nombre: userData.nombre,
      planSuscripcion: userData.planSuscripcion,
      limiteHabitaciones: userData.limiteHabitaciones,
      diasTotalAcumulados: userData.diasTotalAcumulados,
      fechaRenovacionSuscripcion: userData.fechaRenovacionSuscripcion,
      fechaInicioSuscripcion: userData.fechaInicioSuscripcion,
      suscripcionActiva: userData.suscripcionActiva,
    })
  }, [userData]);
  //*********************************OBTENCION DE PROPIEDADES DEL USUARIO LOGUEADO****************************** */
  useEffect(() => {
    onAuthStateChanged(auth, (usuarioFirebase) => {
      if (usuarioFirebase && ownerData) {
        const q = query(collection(db, "propiedades"), where("idPropietario", "==", `${usuarioFirebase.uid}`))

        const unsubscribe = onSnapshot(q, (snapshot) => {
          const propiedadesFirebase = snapshot.docs.map((doc) => ({
            ...doc.data(),
            idPropiedad: doc.id,
          }));
          setPropiedadesOwner(propiedadesFirebase);
        });
        // Devolvemos una función de limpieza para cancelar la suscripción cuando el componente se desmonte.
        return () => unsubscribe();
      } else {
        setCurrentUser(null);
      }
    });
  }, [ownerData]);

  //*******************************OBTENCION DE HABITACIONES DEL USUARIO LOGUEADO********************************* */
  useEffect(() => {
    onAuthStateChanged(auth, (usuarioFirebase) => {
      if (usuarioFirebase && ownerData) {
        const q = query(collection(db, "habitaciones"), where("idPropietario", "==", `${usuarioFirebase.uid}`))

        const unsubscribe = onSnapshot(q, (snapshot) => {
          const habitacionesFirebase = snapshot.docs.map((doc) => ({
            ...doc.data(),
            idHabitacion: doc.id,
          }));
          setHabitacionesOwner(habitacionesFirebase);
        });
        // Devolvemos una función de limpieza para cancelar la suscripción cuando el componente se desmonte.
        return () => unsubscribe();
      } else {
        setCurrentUser(null);
      }
    });
  }, [ownerData]);

  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (usuarioFirebase) => {
      if (usuarioFirebase && ownerData) {
        setCurrentUser([ownerData, propiedadesOwner, habitacionesOwner])
      } else {
        setCurrentUser(null);
      }
    });
    return () => unsubscribeAuth();
  }, [ownerData, propiedadesOwner, habitacionesOwner]);

  return <AuthContextOwner.Provider value={{ currentUser }}>{children}</AuthContextOwner.Provider>;
};
