import styled from "styled-components";
import { Link } from "react-router-dom";
import { colorSecundario, textoOscuro } from "../UI/variables";

const CardMenuOwner = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  color: ${textoOscuro};
  width: 160px;
  height: 200px;
  background-color: white;
  text-decoration: none;
  text-align: center;
  font-weight: 500;
  margin: 0px 0px 8px 0px;
  font-size: 16px;
  border: solid 2px ${colorSecundario};
  border-radius: 16px;
  padding: 8px;
  img{
    margin-bottom: 8px;
    width: 64px;
    height: 64px;
  }
  
  &:hover {
    background-color: black;
  }
  &:active {
    background-color: black;
  }
`;

export default CardMenuOwner;
