import styled from "styled-components";
import calendarioImagen from "../../assets/imagenes/calendarioTarjeta.png";
import alumnosImagen from "../../assets/imagenes/estudiantesTarjeta.png";
import billetesImagen from "../../assets/imagenes/dolaresTarjeta.png";
import casaImagen from "../../assets/imagenes/casaTarjeta.png";
import { textoOscuro } from "../UI/variables";

const StyledSection = styled.section`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  margin: 16px 0 16px 0;
`;
const StyledDiv = styled.div`
  background-color: white;
  width: 160px;
  height: 160px;
  border-radius: 15px;
  text-align: center;
  margin: 4px 8px 4px 8px;
`;
const StyledImg = styled.img`
  width: 72px;
  height: 72px;
  margin: 8px 8px 0px 8px;
`;
const StyledP = styled.p`
  color: ${textoOscuro};
  font-weight: 500;
  width: 144px;
  height: 64px;
  margin: 0px 8px 0px 8px;
  display: flex;
  align-items: center;
`;
const BeneficiosOwner = () => {
  return (
    <StyledSection>
      <StyledDiv>
        <StyledImg src={calendarioImagen} alt="Calendario" />
        <StyledP>Administrar fechas de entrada y salida</StyledP>
      </StyledDiv>
      <StyledDiv>
        <StyledImg src={alumnosImagen} alt="Grupo de estudiantes buscando habitaciones"/>
        <StyledP>Publicar tus habitaciones para estudiantes</StyledP>
      </StyledDiv>
      <StyledDiv>
        <StyledImg src={casaImagen} alt="Explorando una casa"/>
        <StyledP>Visualizar la disponibilidad de habitaciones en tiempo real</StyledP>
      </StyledDiv>
      <StyledDiv>
        <StyledImg src={billetesImagen} alt="Billetes"/>
        {/* <StyledP>Olvídarte de estar cobrando de puerta en puerta</StyledP> */}
        <StyledP>Administrar los pagos de estudiantes</StyledP>
      </StyledDiv>
    </StyledSection>
  );
};
export default BeneficiosOwner;
