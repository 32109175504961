import React from 'react'
import styled from 'styled-components'
import Titulo2 from "../../../components/Titulo2"
import FormEditarPropiedad from '../../../components/FormEditarPropiedad'
import { fondo } from '../../../components/UI/variables'
import Boton from "../../../components/Boton"

const StyledSection = styled.section`
  background-color: ${fondo};
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const StyledHeader = styled.header`
  height: 40px;
  width: 96vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const TituloStyled= styled(Titulo2)`
  width: 100%;
  text-align: center;
`
const StyledP = styled.p`
  margin-top: 8px;
  text-align: center;
`
const EditarPropiedad = () => {
  return (
    <StyledSection>
      <StyledHeader>
        <Boton to="/AgregarPropiedades">Atras</Boton>
        <TituloStyled $naranja>Editar Propiedad</TituloStyled>
      </StyledHeader>
        <StyledP>Vamos a mostrar esta información en tu anuncio y en resultados de búsqueda.</StyledP>
        <FormEditarPropiedad/>
    </StyledSection>
  )
}
export default EditarPropiedad