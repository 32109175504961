import styled from "styled-components";
import { colorSecundario, fondo } from "../UI/variables";
import Boton from "../Boton";
import logo from "../../assets/logo/logoMarca.png";
import { Link } from "react-router-dom";

const StyledHeader = styled.header`
  background-color: ${fondo};
  width: 100vw;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 3px solid ${colorSecundario};
  position: fixed;
  z-index: 1000;
`;

const StyledBotones = styled.div`
  display: flex;
`;

const BotonBorder = styled(Boton)`
  border: 1px solid white;
  text-decoration: none;
  font-size: 12px;
  text-align-last: center;
  text-align: center;
  margin-right: 8px;
`;

const Logo = styled.img`
  width: 136px;
  height: 48px;
  margin-left: 16px;
`;

const HeaderGeneral = () => {
  return (
    <StyledHeader>
      <Link to="/">
        <Logo src={logo} alt="Logo de Arkilados"/>
      </Link>
      <StyledBotones>
        <BotonBorder to="/SignIn">Iniciar Sesión</BotonBorder>
      </StyledBotones>
    </StyledHeader>
  );
};
export default HeaderGeneral;
