import styled from "styled-components";
import { textoClaro } from "./UI/variables";

const BotonEditar = styled.button`
  background-color: #f2ae00;
  height: 40px;
  width: 92px;
  border: none;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${textoClaro};
  font-weight: bold;
  text-decoration: none;
  &:hover {
    background-color: #bca37f;
  }
  &:active {
    background-color: #bca37f;
  }
`;

export default BotonEditar;
