import iconoBañoPrivado from "../../assets/iconos/banoPrivado.svg";
import iconoBañoCompartido from "../../assets/iconos/banoCompartido.svg";
import iconoDuchaAguaFria from "../../assets/iconos/duchaAguaFria.svg";
import iconoDuchaAguaCaliente from "../../assets/iconos/duchaAguaCaliente.svg";
import iconoInternet from "../../assets/iconos/internet.svg";
import iconoTvCable from "../../assets/iconos/tvCable.svg";
import iconoAccesoTendedero from "../../assets/iconos/accesoTendedero.svg";
import iconoVentilador from "../../assets/iconos/ventilador.svg";
import iconoCalefaccion from "../../assets/iconos/calefaccion.svg";

import styled from "styled-components";
import Titulo2 from "../Titulo2";
import { colorTerciario, fondo } from "../UI/variables";
import Boton from "../Boton";

const StyleDiv = styled.div`
  background-color: ${fondo};
  width: 328px;
  height: auto;
  border: 3px solid ${colorTerciario};
  box-sizing: border-box;
  margin-bottom: 16px;
`;
const Imagen = styled.img`
  margin: 8px;
  width: 308px;
  height: 128px;
  object-fit: cover;
`;
const Titulo = styled(Titulo2)`
  display: flex;
  justify-content: center;
  margin: 0;
  height: 16px;
`;
const Descripcion = styled.p`
  display: flex;
  align-items: center;
  width: 312px;
  height: 88px;
  margin: 0;
  margin: 8px 0px 8px 8px;
`;
const DivContenido = styled.div`
  display: flex;
  align-items: space-between;
`;
const ServiciosDiv = styled.div`
  width: 153px;
  height: auto;
  display: flex;
  flex-direction: column;
  margin: 8px 0px 8px 16px;
`;
const Servicios = styled.div`
  display: flex;
  height: 16px;
  width: 148px;
  align-items: center;
`;
const StyledImg = styled.img`
  height: 24px;
  width: 24px;
`;
const Parrafo = styled.p`
  height: 16px;
  font-size: 12px;
  margin: 0;
  margin-left: 5px;
`;
const EquipamientoDiv = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 8px 16px 8px 0px;
  width: 153px;
`;
const Equipamiento = styled.div`
  display: flex;
  height: 16px;
  width: 119px;
  justify-content: space-between;
`;
const Texto = styled.p`
  margin: 0;
`;
const Botones = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 48px;
`;
const StyledP=styled.p`
  font-weight: 500;
  font-size: 16px;
  width: 128px;
  text-align: center;
`
const CardHabitacion = (props) => {
  return (
    <StyleDiv>
      <Imagen src={props.imagen} />
      <Titulo> {`Habitacion ${props.nroHabitacion}`}</Titulo>
      <Descripcion>{props.descripcion}</Descripcion>
      <DivContenido>
        <ServiciosDiv>
          {props.banhoPrivado == true ?
            <Servicios>
              <StyledImg src={iconoBañoPrivado} alt="Baño privado"/>
              <Parrafo>Baño privado</Parrafo>
            </Servicios>
          :<></>
          }
          {props.banhoCompartido == true ?
            <Servicios>
              <StyledImg src={iconoBañoCompartido} alt="Baño compartido"/>
              <Parrafo>Baño compartido</Parrafo>
            </Servicios>
          :<></>
          }
          {props.aguaFria == true ?
            <Servicios>
              <StyledImg src={iconoDuchaAguaFria} alt="Ducha con agua fria"/>
              <Parrafo>Agua fria</Parrafo>
            </Servicios>
          :<></>
          }
          {props.aguaCaliente == true ?
            <Servicios>
              <StyledImg src={iconoDuchaAguaCaliente} alt="Ducha con agua caliente"/>
              <Parrafo>Agua caliente</Parrafo>
            </Servicios>
          :<></>
          }
          {props.internet == true ?
            <Servicios>
              <StyledImg src={iconoInternet} alt="Internet"/>
              <Parrafo>internet</Parrafo>
            </Servicios>
          :<></>
          }
          {props.tvCable == true ?
            <Servicios>
              <StyledImg src={iconoTvCable} alt="TV cable"/>
              <Parrafo>TV cable</Parrafo>
            </Servicios>
          :<></>
          }
          {props.accesoTendedero == true ?
            <Servicios>
              <StyledImg src={iconoAccesoTendedero} alt="Acceso a tendedero de ropa"/>
              <Parrafo>Acceso a tendedero</Parrafo>
            </Servicios>
          :<></>
          }
          {props.ventilacion == true ?
            <Servicios>
              <StyledImg src={iconoVentilador} alt="Ventilacion"/>
              <Parrafo>Ventilación</Parrafo>
            </Servicios>
          :<></>
          }
          {props.calefaccion == true ?
            <Servicios>
              <StyledImg src={iconoCalefaccion} alt="Calefaccion"/>
              <Parrafo>Calefacción</Parrafo>
            </Servicios>
          :<></>
          }
        </ServiciosDiv>

        <EquipamientoDiv>
          <Equipamiento>
            <Texto>. Sillas</Texto>
            <Texto>{`${props.sillas}`}</Texto>
          </Equipamiento>
          <Equipamiento>
            <Texto>. Camas</Texto>
            <Texto>{`${props.camas}`}</Texto>
          </Equipamiento>
          <Equipamiento>
            <Texto>. Armarios</Texto>
            <Texto>{`${props.armarios}`}</Texto>
          </Equipamiento>
          <Equipamiento>
            <Texto>. Mesas</Texto>
            <Texto>{`${props.mesas}`}</Texto>
          </Equipamiento>
          <Equipamiento>
            <Texto>. Veladores</Texto>
            <Texto>{`${props.veladores}`}</Texto>
          </Equipamiento>
        </EquipamientoDiv>
      </DivContenido>

      <Botones>
        <StyledP>{`Area: ${props.area}m²`}</StyledP>
        <Boton $gris>{`S/. ${props.precio} / mes`}</Boton>
      </Botones>
    </StyleDiv>
  );
};

export default CardHabitacion;
