import React, { createContext, useEffect, useState } from "react";
import appFirebase from "../firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { useAuth } from "./AuthContext";

const auth = getAuth(appFirebase);

export const AuthContextStudent = createContext();
export const AuthProviderStudent = ({ children }) => {

  const { userData, usuarioActual } = useAuth(); //traemos datos de database y autentication de firebase desde el AuthContext */
  const [currentUser, setCurrentUser] = useState(null);
  const [propiedades, setPropiedades] = useState([]);
  const [habitaciones, setHabitaciones] = useState([]);
  const [studentData, setStudentData] = useState(null);

  //************************Conformacion de studentData con datos de database y autentication****************** */
  useEffect(() => {
    setStudentData({
      uid: usuarioActual.uid,
      correo: usuarioActual.email,
      rol: userData.rol,
      uuid: userData.uuid,
      nombre: userData.nombre
    })
  }, [userData, usuarioActual]);


  useEffect(() => {
    const unsubscribeAuth = onAuthStateChanged(auth, (usuarioFirebase) => {
      if (usuarioFirebase && studentData) {
        setCurrentUser([studentData, propiedades, habitaciones])
      } else {
        setCurrentUser(null);
      }
    })
    return () => unsubscribeAuth();
  }, [studentData, propiedades, habitaciones]);

  return <AuthContextStudent.Provider value={{ currentUser, setPropiedades, setHabitaciones }}>{children}</AuthContextStudent.Provider>;
};
