import CardMenuOwner from "../../../components/CardMenuOwner"
import styled from "styled-components"
import HeaderLoginOwner from "../../../components/HeaderLoginOwner"
import IconGestion from "../../../assets/iconos/cardGestion.svg"
import Chart from "../../../assets/iconos/chart.svg"
import IconPropiedades from "../../../assets/iconos/propiedades.svg"
import IconHabitaciones from "../../../assets/iconos/habitaciones.svg"
import { fondo } from "../../../components/UI/variables"
import NavigationTab from "../../../components/NavigationTab"

const StyledSection = styled.div`
  background-color: ${fondo};
  height: 90vh;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const StyledDiv = styled.div`
  display: flex;
  height: calc(100% - 72px - 174px);
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
`
const StyledP = styled.div`
  width: 300px;
  text-align: center;
  margin: 112px 0px 24px 0px;
  font-size: 16px;
`
const HomeOwnerLogin = ()=>{
    return(
        <StyledSection>
            <HeaderLoginOwner/>
            <StyledP>Bienvenido, inicia registrando tus propiedades y habitaciones.</StyledP>
            <StyledDiv>
                <CardMenuOwner to="/AgregarPropiedades">
                    <img src={IconPropiedades} alt="Registra tus propiedades"/>
                    1. Registra tus propiedades
                </CardMenuOwner>
                <CardMenuOwner to="/AgregarHabitaciones">
                    <img src={IconHabitaciones} alt="Registra tus habitaciones"/>
                    2. Registra tus habitaciones
                </CardMenuOwner>
                <CardMenuOwner to="/RegistrarInquilinos">
                    <img src={IconGestion} alt="Registra tus inquilinos"/>
                    3. Registra tus inquilinos
                </CardMenuOwner>
                <CardMenuOwner to="/ControlarPagos">
                    <img src={Chart} alt="Controla tus pagos"/>        
                    4. Controla tus pagos
                </CardMenuOwner>
            </StyledDiv>
            <NavigationTab/>
        </StyledSection>
    )
}

export default HomeOwnerLogin