import styled from "styled-components";
import casaIcon from "../../assets/iconos/casa.svg";
import lupaIcon from "../../assets/iconos/lupa.svg";

const StyledDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const StyledIcon = styled.img`
  height: 48px;
  width: 48px;
  margin: 16px;
`;
const Parrafo = styled.p`
  width: 200px;
`;
const BeneficiosStudent = () => {
  return (
    <div>
      <StyledDiv>
        <StyledIcon src={casaIcon} alt="Icono de casa"/>
        <Parrafo>Arkila habitaciones a tu medida</Parrafo>
      </StyledDiv>
      <StyledDiv>
        <StyledIcon src={lupaIcon} alt="Icono de lupa"/>
        <Parrafo>Habitaciones faciles de encontrar</Parrafo>
      </StyledDiv>
    </div>
  );
};
export default BeneficiosStudent;
