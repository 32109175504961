import styled from "styled-components";
import { colorSecundario } from "./UI/variables";

const BotonEliminar = styled.button`
  border: 1px solid #da2d2d;
  height: 40px;
  width: 60px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0px;
  color: #da2d2d;
  background-color: ${colorSecundario};
  &:hover {
    background-color: gray;
  }
  &:active {
    background-color: gray;
  }
`;

export default BotonEliminar;
