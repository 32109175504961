import styled from "styled-components";
import { colorPrimario, colorTerciario, textoClaro } from "./UI/variables";

const BotonSubmit = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 0px 8px 0px;
  color: ${textoClaro};
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  background-color: ${(props) =>
    props.$gris ? colorTerciario : colorPrimario};
  height: 40px;
  width: ${(props) => (props.$large ? "328px" : "128px")};
  text-decoration: none;
  &:hover {
    background-color: ${(props) => props.$procesoenvioiniciado==true ? "black" : colorPrimario};
    font-size: 16px;
  }
  &:active {
    background-color: ${(props) => props.$procesoenvioiniciado==true ? "black" : colorPrimario};
    font-size: 16px;
  }
`;

export default BotonSubmit;
