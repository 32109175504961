import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContextAdmin } from "../context/AuthAdmin";

const PrivateRouteAdmin = () => {
  const { currentUser } = useContext(AuthContextAdmin);

  return !!currentUser ? <Outlet /> : <Navigate to="/SignIn" />;
};

export default PrivateRouteAdmin;
