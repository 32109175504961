import styled from 'styled-components'
import RedaccionTyC from "../../components/RedaccionTyC"
import Boton from '../../components/Boton'
import Titulo2 from '../../components/Titulo2'

const StyledSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
`
const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  height: 40px;
  width: 96vw;
  align-items: center;
`
const TituloStyled= styled(Titulo2)`
  width: 100%;
  text-align: center;
`
const cerrarPestaña = () => {
  window.close();
};
const TyC = () => {
  return (
    <StyledSection>
        <StyledHeader>
            <Boton onClick={cerrarPestaña}>Cerrar</Boton>
            <TituloStyled $naranja>Terminos y condiciones</TituloStyled>
          </StyledHeader>
        <RedaccionTyC/>
    </StyledSection>
  )
}
export default TyC