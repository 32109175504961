import React, { useState, useRef, useContext, useEffect } from "react";
import Etiqueta from "../Etiqueta";
import InputHorizontal from "../InputHorizontal";
import InputHorizontalRequired from "../InputHorizontalRequired";
import AreaTexto from "../AreaTexto";
import CheckboxServicios from "../CheckboxServicios";
import Entrada from "../Entrada";
import BotonSubmit from "../BotonSubmit";
import styled from "styled-components";
import imagenTextoBottomH from "../../assets/imagenes/imagenTextoBottomH.png";
import appFirebase from "../../firebase";
import { getFirestore, collection, addDoc, doc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate, useParams } from "react-router-dom";
import { AuthContextOwner } from "../../context/AuthOwner";
import { textoOscuro } from "../UI/variables";
import { v4 as uuidv4 } from "uuid";
import { colorPrimario } from "../../components/UI/variables";
import mascota from "../../assets/imagenes/mascota.png";

const StyledForm = styled.form`
  margin: 8px 0 16px 0;
  display: flex;
  flex-direction: column;
  width: 328px;
`;
const EntradaFile = styled(Entrada)`
  // display: none;
`;
const StyledLabel = styled.label`
  width: 328px;
  height: 200px;
  border: 2px dashed ${textoOscuro};
  margin: 8px 0 16px 0;
`;
const StyledImg = styled.img`
  width: 328px;
  height: 200px;
  object-fit: cover;
`;
const StyledSectionDefault = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  height: 100%;
`;
const StyledDivDefault = styled.div`
  display: flex;
  align-items: center;
  margin: 100px 8px 0px 8px;

  div {
    display: flex;
    flex-direction: column;
  }
  p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 18px;
    border: solid 2px ${colorPrimario};
    border-radius: 16px;
    padding: 8px;
    margin: 8px 0px 0px 0px;
  }
  img {
    width: 140px;
    height: 200px;
  }
`;
const db = getFirestore(appFirebase);
const storage = getStorage(appFirebase);

const FormHabitacion = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContextOwner);
  const idPropietario = currentUser[0].uid;
  const habitaciones = currentUser[2];
  let { idPropiedad } = useParams();
  //Funcionamiento de inputs Horizontales
  const [nroHabitacion, setNroHabitacion] = useState("");
  const [area, setArea] = useState("");
  const [sillas, setSillas] = useState("");
  const [camas, setCamas] = useState("");
  const [armarios, setArmarios] = useState("");
  const [mesas, setMesas] = useState("");
  const [veladores, setVeladores] = useState("");

  const [sourceImagen, setSourceImagen] = useState(imagenTextoBottomH);
  const [procesoEnvioIniciado, setProcesoEnvioIniciado] = useState(false);

  const propiedades = currentUser[1];
  const [uuidPropiedad, setUuidPropiedad] = useState("");
  const limiteHabitaciones = currentUser[0].limiteHabitaciones;
  const [habitacionesPropietario, setHabitacionesPropietario] = useState([]);

  const [preciosHabitacionesFirebase, setPrecioHabitacionesFirebase] = useState([]);
  const [preciosHabitacionesEnviar, setPreciosHabitacionesEnviar] = useState([]);

  useEffect(() => {
    propiedades.map((propiedad) => {
      if (propiedad.idPropiedad == idPropiedad) {
        setUuidPropiedad(propiedad.uuidPropiedad);
        setPrecioHabitacionesFirebase(propiedad.preciosHabitaciones);
      }
    });
  }, []);

  useEffect(() => {
    let habitacionesFiltradas = [];
    habitaciones.map((habitacion) => {
      if (habitacion.idPropietario == idPropietario) {
        habitacionesFiltradas.push(habitacion);
        setHabitacionesPropietario(habitacionesFiltradas);
      }
    });
  }, [habitaciones]);

  // ****************************OBTENCION DE VALORES DE LOS DIFERENTES TIPOS DE INPUTS************************ */
  const obtenerNroHabitacion = (valor) => {
    setNroHabitacion(valor);
  };
  const obtenerArea = (valor) => {
    setArea(valor);
  };
  const obtenerSillas = (valor) => {
    setSillas(valor);
  };
  const obtenerCamas = (valor) => {
    setCamas(valor);
  };
  const obtenerArmarios = (valor) => {
    setArmarios(valor);
  };
  const obtenerMesas = (valor) => {
    setMesas(valor);
  };
  const obtenerVeladores = (valor) => {
    setVeladores(valor);
  };

  // Funcionamiento de input vertical y textarea
  const [descripcion, setDescripcion] = useState("");
  const [precio, setPrecio] = useState("");

  const obtenerDescripcion = (e) => {
    setDescripcion(e.target.value);
  };
  const obtenerPrecio = (e) => {
    setPrecio(parseInt(e.target.value));
  };

  //Obtenemos valores desde el componente CheckboxServicios
  const [serviciosHabitacion, setServiciosHabitacion] = useState("");
  const obtenerServicios = (checkboxValues) => {
    setServiciosHabitacion(checkboxValues);
  };

  const fileInputRef = useRef(null);
  //Obtenemos y almacenamos archivo en const imagen
  const [imagen, setImagen] = useState("");

  const manejarImagen = (e) => {
    setImagen(e.target.files[0]);
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = function (event) {
        setSourceImagen(event.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setSourceImagen(imagenTextoBottomH);
    }
  };

  //****************************************************************************************************************
  useEffect(() => {
    const preciosHabitaciones = [...preciosHabitacionesFirebase]
    preciosHabitaciones.push(precio)
    setPreciosHabitacionesEnviar(preciosHabitaciones)
  }, [precio]);

  const confirmarInicioDeEnvio = () => {
    setProcesoEnvioIniciado(true);
  };

  let urlImagen;
  const uuidHabitacion = uuidv4();

  const manejarEnvio = async (e) => {
    e.preventDefault();
    confirmarInicioDeEnvio();

    if (imagen) {
      // Si se ha seleccionado una imagen, carga la imagen al storage y obtén la URL
      const refImagen = ref(
        storage,
        `usuarios/propietarios/${currentUser[0].uid}/propiedades/${uuidPropiedad}/habitaciones/${uuidHabitacion}/imagenesHabitacion/${uuidPropiedad}-${uuidHabitacion}-imagen1`
      ); //definimos ubicacion de imagen en storage firebase donde crearemos carpeta propiedades
      await uploadBytes(refImagen, imagen); //subimos la const imagen a la ubicacion definida en refImagen mediante la funcion uploadBytes
      //obtenemos la url de la imagen subida al storage
      urlImagen = await getDownloadURL(refImagen);
    }

    const datosHabitacion = {
      NroHabitacion: nroHabitacion,
      Area: area,
      Sillas: sillas,
      Camas: camas,
      Armarios: armarios,
      Mesas: mesas,
      Veladores: veladores,
      Descripcion: descripcion,
      Precio: precio,
      Imagen: urlImagen,
      idPropiedad: idPropiedad,
      idPropietario: idPropietario,
      uuidHabitacion: uuidHabitacion,
      disponible: true,
      dni: "",
      celular: "",
      nombreCompleto: "",
      fechaEntrada: "",
      fechaInicioPeriodoPagado: "",
      fechaFinPeriodoPagado: "",
      periodoPagado: "",
    };

    //Se fusionan los datosInputs y los datos de servicios traidos desde el componente CheckboxServicios
    const newHabitacion = { ...serviciosHabitacion, ...datosHabitacion };
    //enviar datos recolectados al database
    try {
      await addDoc(collection(db, "habitaciones"), { ...newHabitacion });
    } catch (error) {
      console.log(error);
    }

    //*******************enviar datos modificados de la propiedad a la que pertenece la habitacion a firebase*************************
    const datosModificadosPropiedad = {
      disponibilidadPropiedad: true,
      preciosHabitaciones: preciosHabitacionesEnviar,
    };
    try {
      const documentoRef = doc(db, "propiedades",idPropiedad);
      await updateDoc(documentoRef, datosModificadosPropiedad);
    } catch (error) {
      console.error("Error al modificar disponibilidadPropiedad:", error);
    }
    //nos direccionamos a la pagina donde estan registradas las propiedades
    navigate("/AgregarHabitaciones");
  };

  return habitacionesPropietario.length < limiteHabitaciones ? (
    <section>
      <StyledForm onSubmit={manejarEnvio}>
        <Etiqueta>Asigne un número a la habitacion y su área</Etiqueta>
        <InputHorizontalRequired
          obtenerDatos={obtenerNroHabitacion}
          $etiqueta="Habitación N°:"
        />
        <InputHorizontalRequired
          obtenerDatos={obtenerArea}
          $etiqueta="Area(m2):"
        />
        <Etiqueta>
          Describa lo mas resaltante de la habitación:
          <AreaTexto onChange={obtenerDescripcion} maxLength="180" required />
        </Etiqueta>
        <Etiqueta>
          Seleccione los servicios con los que cuenta la habitación:
        </Etiqueta>
        <CheckboxServicios obtenerDatos={obtenerServicios} />
        <Etiqueta>
          Llene la cantidad de muebles con los que cuenta esta habitación (si no
          cuenta con ninguno, deje los espacios en cero):
        </Etiqueta>
        <InputHorizontal obtenerDatos={obtenerSillas} $etiqueta="Sillas:" />
        <InputHorizontal obtenerDatos={obtenerCamas} $etiqueta="Camas:" />
        <InputHorizontal obtenerDatos={obtenerArmarios} $etiqueta="Armarios:" />
        <InputHorizontal obtenerDatos={obtenerMesas} $etiqueta="Mesas:" />
        <InputHorizontal
          obtenerDatos={obtenerVeladores}
          $etiqueta="Veladores:"
        />
        <Etiqueta>Asigne el precio de la habitación (S/.)</Etiqueta>
        <Entrada onChange={obtenerPrecio} type="number" required />
        <Etiqueta>Agrega 01 imagen de la habitacion:</Etiqueta>

        <EntradaFile
          type="file"
          name="image"
          accept="image/*"
          //capture="environment"
          onChange={manejarImagen}
          ref={fileInputRef}
          id="inputImagen"
          required
        />
        <StyledLabel htmlFor="inputImagen">
          <StyledImg src={sourceImagen} alt="Seleccionar fotografia de habitacion"/>
        </StyledLabel>
        <BotonSubmit $large $procesoenvioiniciado={procesoEnvioIniciado} disabled={procesoEnvioIniciado ? true : false}>
          {procesoEnvioIniciado ? "Registrando Habitacion...⌛⌛" : "Registrar Habitacion"}
        </BotonSubmit>
      </StyledForm>
    </section>
  ) : (
    <StyledSectionDefault>
      <StyledDivDefault>
        <div>
          <p>
            Ups! llegaste al limite de habitaciones registradas segun tu plan
            contratado
          </p>
          <p>
            Si deseas ampliar tu plan para registrar mas habitaciones ponte
            en contacto con "Arkilados"
          </p>
        </div>
        <img src={mascota} alt="Mascota de arkilados - Perro Cuartelero"/>
      </StyledDivDefault>
    </StyledSectionDefault>
  );
};

export default FormHabitacion;
