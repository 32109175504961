import React from 'react'
import styled from 'styled-components'

import Titulo2 from "../../../components/Titulo2"
import FormPropiedad from '../../../components/FormPropiedad'
import { fondo } from '../../../components/UI/variables'
import Boton from '../../../components/Boton'

const StyledSection = styled.section`
  background-color: ${fondo};
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  height: 40px;
  width: 96vw;
  align-items: center;
`
const TituloStyled= styled(Titulo2)`
  width: 100%;
  text-align: center;
`
const StyleP = styled.p`
  margin-top: 8px;
  text-align: center;
`

const RegistroPropiedad = () => {
  return (
    <StyledSection>
      <StyledHeader>
        <Boton to="/AgregarPropiedades">Atras</Boton>
        <TituloStyled $naranja>Registro de Propiedad</TituloStyled>
      </StyledHeader>
        <StyleP>Llena el formulario para registrar tu propiedad y lo estudiantes te contactaran.</StyleP>
        <FormPropiedad/>
    </StyledSection>
  )
}

export default RegistroPropiedad