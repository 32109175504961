import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components';
import { AuthContextAdmin } from '../../context/AuthAdmin';

const StyledInput = styled.input`
    height: 32px;
    font-size: 24px;
    text-align: center;
    font-weight: bold;
`

const BarraBusqueda = ({obtenerPropietariosFiltrados}) => {
    const { currentUser } = useContext(AuthContextAdmin);
    const propietarios = currentUser[1];
    const [consulta, setConsulta] = useState("");
    const handleConsultaChange = (event) => {
        setConsulta(event.target.value);
    };

    useEffect(()=>{
        const resultados = propietarios.filter(propietario => propietario.dni.toString().includes(consulta) ||
        propietario.correo.toLowerCase().includes(consulta.toLowerCase())
        );
        obtenerPropietariosFiltrados(resultados);
    },[consulta, propietarios])

    return (
        <div>
            <StyledInput
                type="text"
                value={consulta}
                onChange={handleConsultaChange}
                placeholder="Buscar DNI o Correo"
            />
        </div>
    );
}
export default BarraBusqueda