import React, { useState } from "react";
import styled from "styled-components";
import { colorPrimario, textoClaro } from "../UI/variables";

const StyledButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 0px 8px 0px;
  color: ${textoClaro};
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  background-color: ${colorPrimario};
  height: 40px;
  width: 152px;
  text-decoration: none;
`;
const StyledDialog = styled.dialog`
  border-radius: 10px;
  border: 1px solid ${colorPrimario};
  align-items: center;
  width: 240px;
  height: 88px;
`;
const StyledP = styled.p`
  margin: 0 0 8px 0;
  text-align: center;
`;
const BotonCerrar = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 0px 8px 56px;
  color: ${textoClaro};
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  background-color: ${colorPrimario};
  height: 32px;
  width: 128px;
  text-decoration: none;
`;

const DialogContactar = ({ celular }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
  };

  const abrirDialog = () => {
    setIsOpen(true);
  };

  return (
    <section>
      <StyledDialog open={isOpen} onClose={handleClose}>
        <StyledP>Coordina con el propietario =)</StyledP>
        <StyledP>
          <strong>Celular:</strong> {`${celular}`}
        </StyledP>
        <BotonCerrar onClick={handleClose}>Cerrar</BotonCerrar>
      </StyledDialog>
      <StyledButton onClick={abrirDialog}>Contactar</StyledButton>
    </section>
  );
};
export default DialogContactar;
