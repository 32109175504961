import styled from "styled-components";
import { colorPrimario, textoOscuro } from "./UI/variables";

const Titulo2 = styled.h2`
  color: ${(props) => (props.$naranja ? colorPrimario : textoOscuro)};
  font-size: 16px;
  font-weight: 700;
`;

export default Titulo2;
