import React, { useContext } from "react";
import PropiedadRegistrada from "../PropiedadRegistrada";
import { AuthContextOwner } from "../../context/AuthOwner";

const ListaPropiedadesRegistradasOwner = () => {
  const { currentUser } = useContext(AuthContextOwner);
  const propiedadesOwner= currentUser[1];

  return (
    <div>
      {propiedadesOwner.map((propiedadOwner, i) => (
        <PropiedadRegistrada
          key={i}
          imagen={propiedadOwner.imagen}
          direccion={propiedadOwner.direccion}
          idPropiedad={propiedadOwner.idPropiedad}
          uuidPropiedad={propiedadOwner.uuidPropiedad}
        />
      ))}
    </div>
  );
};

export default ListaPropiedadesRegistradasOwner;
