import React, { useState, useCallback, useEffect } from "react";
import { GoogleMap, useJsApiLoader, Marker } from "@react-google-maps/api";

const containerStyle = {
  width: "100%",
  height: "50vh",
  margin: "8px 0px 8px 0px ",
};

const MapaRegistroPropiedad = ({
  ubicacion,
  getLatitud,
  getLongitud,
  latitud,
  longitud,
}) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const coordsPuno = { lat: -15.84054473927132, lng: -70.0276914112913 };
  const coordsMoquegua = { lat: -17.193877, lng: -70.934478 };
  const coordsArequipa = { lat: -16.398694704816343, lng: -71.53695703436338 };
  const coordsCuzco = { lat: -13.51656, lng: -71.97813 };
  const coordsEscritas = { lat: Number(latitud), lng: Number(longitud) };

  const [map, setMap] = useState(null);
  const [center, setCenter] = useState({ lat: 0, lng: 0 });

  //*************modificacion del centro del mapa de acuerdo a ubicacion e inputs************/
  useEffect(() => {
    if (ubicacion == "Puno") {
      setCenter(coordsPuno);
    } else if (ubicacion == "Moquegua") {
      setCenter(coordsMoquegua);
    } else if (ubicacion == "Arequipa") {
      setCenter(coordsArequipa);
    } else if (ubicacion == "Cuzco") {
      setCenter(coordsCuzco);
    }
  }, [ubicacion]);

  useEffect(() => {
    setCenter(coordsEscritas);
  }, [latitud, longitud]);

  //*******************montar, desmontar el mapa y tipo de desplazamiento(pan)****************/
  const onLoad = useCallback(function callback(map) {
    setMap(map);
  }, []);
  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  useEffect(() => {
    if (map) {
      const newCenter = new window.google.maps.LatLng(center.lat, center.lng);
      map.panTo(newCenter);
    }
  }, [map, coordsEscritas]);

  //**********************modifica el centro del mapa al hacer click en mapa****************** */
  const manejarMapClick = (e) => {
    const clickedLocation = {
      lat: e.latLng.lat(),
      lng: e.latLng.lng(),
    };
    getLatitud(clickedLocation.lat);
    getLongitud(clickedLocation.lng);
    setCenter(clickedLocation);
  };

  //*************************************renderizacion del mapa****************************** */
  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={14}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onClick={manejarMapClick}
      options={{clickableIcons: false,}}
    >
      <Marker position={center} map={map} />
    </GoogleMap>
  ) : (
    <></>
  );
};

export default MapaRegistroPropiedad;
