import styled from 'styled-components';

const StyledDiv=styled.div`
    height: 740px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const StyledP404=styled.p`
    font-size: 48px;
    font-weight: 700;
    margin: 0;

`
const StyledP=styled.p`
    font-size: 24px;
    font-weight: 500;
    margin: 0;
`

const Error404=()=>{
    return(
        <StyledDiv>
            <StyledP404>404</StyledP404>
            <StyledP>Page not found😞</StyledP>

        </StyledDiv>

    )
}
export default Error404