import React from 'react'
import HeaderLoginAdmin from "../../../components/HeaderLoginAdmin"
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import dolarCalendario from '../../../assets/iconos/dolarCalendario.svg'
import usuarioBloqueado from '../../../assets/iconos/usuarioBloqueado.png'
import tiempoCaducado from '../../../assets/iconos/tiempoCaducado.png'
import { colorPrimario } from '../../../components/UI/variables'

const DivContenedor = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
`
const StyledLink = styled(Link)`
  text-align: center;
  font-weight: bold;
  text-decoration: none;
  font-size: 16px;
  display: flex;
  align-items: center;
  height: 80px;
  width: 160px;
  background-color: ${(props)=>props.color};
  padding: 16px;
  border-radius: 16px;
  margin-bottom: 32px;
  img{
    width: 48px;
    height: 48px;
  }
  P{
    color: white;
  }
`
const HomeAdmin = () => {
  return (
    <section>
      <HeaderLoginAdmin/>
      <DivContenedor>
        <h1>Gestionar Propietarios</h1>
        <StyledLink to={"/GestionarSuscripciones"} color="#4ABDD2;">
          <img src={dolarCalendario} alt="Gestionar Suscripciones"/>
          <p>Gestionar Suscripciones</p>
        </StyledLink>

        <StyledLink to={"/BloquearPropietarios"} color="red">
          <img src={usuarioBloqueado} alt="Bloquear Propietarios"/>
          <p>Bloquear Propietarios</p>
        </StyledLink>

        <h1>Notificaciones Habitaciones</h1>
        <StyledLink to={"/VerHabitacionesVencidas"} color={`${colorPrimario}`}>
          <img src={tiempoCaducado} alt="HabitacionesVencidas"/>
          <p>Ver Habitaciones Vencidas</p>
        </StyledLink>

        
      </DivContenedor>
    </section>
  )
}

export default HomeAdmin