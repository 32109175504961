import React, { useEffect, useState, useContext } from "react";
import HabitacionRegistrada from "../HabitacionRegistrada";
import { AuthContextOwner } from "../../context/AuthOwner";

const ListaHabitacionesRegistradasOwner = ({ contarHabitaciones, propiedadSeleccionada }) => {
  const idPropiedad = propiedadSeleccionada.idPropiedad
  const preciosHabitacionesFirebase = propiedadSeleccionada.preciosHabitaciones
  const { currentUser } = useContext(AuthContextOwner);
  const habitacionesOwner = currentUser[2];
  const [lista, setLista] = useState([]);

  const habitacionesPropiedad = [];
  habitacionesOwner.map((habitacionOwner) => {
    if (habitacionOwner.idPropiedad == idPropiedad) {
      habitacionesPropiedad.push(habitacionOwner);
    }
  });

  useEffect(() => {
    const sortedLista = habitacionesPropiedad.sort((a, b) => a.NroHabitacion - b.NroHabitacion);
    setLista(sortedLista);
    contarHabitaciones(sortedLista.length);
  }, [propiedadSeleccionada, habitacionesOwner, currentUser]);

  return (
    <div>
      {lista.map((list, i) => {
        let preciosHabitacionesEnviar
        const precioEliminar = parseInt(list.Precio)
        if(precioEliminar){
          const index = preciosHabitacionesFirebase.indexOf(precioEliminar)
          if(index!=-1){
            const preciosHabitaciones = [...preciosHabitacionesFirebase]
            preciosHabitaciones.splice(index,1)
            preciosHabitacionesEnviar=preciosHabitaciones
          }
        }
        return <HabitacionRegistrada
          key={i}
          imagen={list.Imagen}
          nroHabitacion={list.NroHabitacion}
          precio={list.Precio}
          idHabitacion={list.idHabitacion}
          idPropiedad={list.idPropiedad}
          uuidHabitacion={list.uuidHabitacion}
          habitacionesPropiedad={habitacionesPropiedad}
          disponible={list.disponible}
          preciosHabitacionesEnviar={preciosHabitacionesEnviar}
        />
})}
    </div>
  );
};

export default ListaHabitacionesRegistradasOwner;
