import styled from "styled-components";
import { Link } from "react-router-dom";
import { colorPrimario, colorTerciario, textoClaro } from "./UI/variables";

const Boton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 0px 8px 0px;
  color: ${textoClaro};
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  background-color: ${(props) =>
    props.$gris ? colorTerciario : colorPrimario};
  height: 40px;
  width: ${(props) => (props.$large ? "328px" : "128px")};
  text-decoration: none;
  transition: background-color 1s ease;
  &:hover {
    background-color: black;
  }
  &:active {
    background-color: black;
  }
`;

export default Boton;
