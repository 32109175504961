import styled from "styled-components"
import logoWhatsapp from "../../assets/iconos/logoWhatsapp.svg"
import logoFacebook from "../../assets/iconos/logoFacebook.svg"
import logoCorreo from "../../assets/iconos/logoCorreo.svg"
import { colorSecundario } from "../UI/variables"

const StyledSection = styled.section`
    display: flex;
    justify-content: center;
    width: 144px;
`
const ContenedorInfo = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
`
const StyledDiv = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${colorSecundario};
    width: 264px;
    height: 40px;
    margin: 2px 0px;
`
const StyledP = styled.p`
    width: auto;
    height: 16px;
    margin: 0;
    text-align: center;
`
const BoxContacto = () => {
  return (
    <StyledSection>
        <ContenedorInfo>
            <h2>Contactanos y adquiere tu plan:</h2>
            <StyledDiv>
                <img src={logoWhatsapp} alt="Logo de Whatsapp"/>
                <StyledP>935863469</StyledP>
            </StyledDiv>
            {/* <StyledDiv>
                <img src={logoFacebook} alt="Logo de Facebook"/>
                <StyledP>arkilados_conf</StyledP>
            </StyledDiv> */}
            <StyledDiv>
                <img src={logoCorreo} alt="Icono de correo"/>
                <StyledP>arkiladosatencion@gmail.com</StyledP>
            </StyledDiv>
        </ContenedorInfo>
    </StyledSection>
  )
}

export default BoxContacto