import React, { useState, useCallback, useContext } from "react";
import styled from "styled-components";
import Entrada from "../Entrada";
import Desplegable from "../Desplegable";
import BotonSubmit from "../BotonSubmit";
import appFirebase from "../../firebase";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { AuthContextOwner } from "../../context/AuthOwner";

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  margin: 16px 16px 16px 16px;
  flex-wrap: wrap;
`;
const StyledLabel=styled.label`
  font-size: 12px;
  align-self: flex-start;
`

const FormOwnerComplementario = () => {
  const auth = getAuth(appFirebase);
  const db = getFirestore(appFirebase);
  const { currentUser } = useContext(AuthContextOwner);
  const navigate = useNavigate();

  const [nombre, setNombre] = useState(currentUser[0].nombre);
  const [apellido, setApellido] = useState("");
  const [dni, setDni] = useState("");
  const [ubicacion, setUbicacion] = useState(currentUser[0].ubicacion);
  const [celular, setCelular] = useState("");
  const [procesoEnvioIniciado, setProcesoEnvioIniciado] = useState(false);

  const idUsuario = currentUser[0].uid;

  const manejarNombre = (e) => {
    setNombre(e.target.value);
  };
  const manejarApellido = (e) => {
    setApellido(e.target.value);
  };
  const manejarDni = (e) => {
    if(e.target.value.length <= 8){
      setDni(e.target.value);
    }
  };
  const manejarUbicacion = (valor) => {
    setUbicacion(valor);
  };
  const manejarCelular = (e) => {
    if (e.target.value.length <= 9) {
      setCelular(e.target.value);
    }
  };
  const confirmarInicioDeEnvio = () => {
    setProcesoEnvioIniciado(true);
  };

  const manejarEnvio = useCallback(
    async (e) => {
      e.preventDefault();
      confirmarInicioDeEnvio();

      const newRegistro = {
        nombre: nombre,
        apellido: apellido,
        dni: dni,
        ubicacion: ubicacion,
        celular: celular,
      };

      try {
        //Creamos una referencia con la ubicacion del documento de firebase que deseamos actualizar
        const documentoRef = doc(db, "usuarios", idUsuario);
        //Actualizamos el documento en firebase
        await updateDoc(documentoRef, newRegistro);
      } catch (error) {
        console.log(error);
      }
      navigate("/HomeOwnerLogin");
    },
    [navigate, nombre, apellido, dni, ubicacion, celular,]
  );

  return (
    <div>
      <StyledForm onSubmit={manejarEnvio}>
        <StyledLabel>Nombres:</StyledLabel>
        <Entrada type="text" value={nombre} onChange={manejarNombre} required />
        <StyledLabel>Apellidos:</StyledLabel>
        <Entrada type="text" value={apellido} onChange={manejarApellido} required />
        <StyledLabel>Dni:</StyledLabel>
        <Entrada type="number" value={dni} onChange={manejarDni} required />
        <StyledLabel>Ubicacion:</StyledLabel>
        <Desplegable departamento={ubicacion} onSeleccionChange={manejarUbicacion} />
        <StyledLabel>Celular:</StyledLabel>
        <Entrada type="number" value={celular} onChange={manejarCelular} required />
        <BotonSubmit $large $procesoenvioiniciado={procesoEnvioIniciado} disabled={procesoEnvioIniciado ? true : false}>{procesoEnvioIniciado ? "Preparando tu espacio... ⌛⌛" : "Empieza a Administrar"}</BotonSubmit>
      </StyledForm>
    </div>
  );
};

export default FormOwnerComplementario;
