import React from 'react'
import styled from 'styled-components'
import { fondo } from '../../../components/UI/variables'

import Titulo2 from "../../../components/Titulo2"
import FormEditarHabitacion from '../../../components/FormEditarHabitacion'
import Boton from '../../../components/Boton'

const StyledSection = styled.section`
  background-color: ${fondo};
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  height: 40px;
  width: 96vw;
  align-items: center;
`
const TituloStyled= styled(Titulo2)`
  width: 100%;
  text-align: center;
`
const EditarHabitacion = () => {
  return (
    <StyledSection>
      <StyledHeader>
        <Boton to={`/AgregarHabitaciones`}>Atras</Boton>
        <TituloStyled $naranja>Editar Habitacion</TituloStyled>
      </StyledHeader>
      <FormEditarHabitacion/>
    </StyledSection>
  )
}

export default EditarHabitacion