import React, { useEffect, useState, useContext} from 'react'
import HeaderLoginStudentAtras from "../../../components/HeaderLoginStudentAtras"
import styled from 'styled-components'
import { colorSecundario, colorTerciario, fondo, textoClaro } from '../../../components/UI/variables'
import ListaHabitacionesStudent from '../../../components/ListaHabitacionesStudent'
import { useParams } from 'react-router-dom'
import DialogContactar from '../../../components/DialogContactar'
import DialogReglas from '../../../components/DialogReglas'
import { AuthContextStudent } from '../../../context/AuthStudent'

const StyledSection=styled.div`
  background-color:${fondo};
  padding-top:88px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const StyledDiv=styled.div`
  position: fixed;
  background-color:${colorSecundario};
  margin-top: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const PEnunciado = styled.p`
  text-align: center;
  margin: 8px 8px 0px 8px;
  font-weight: 600;
`
const DivPropiedad=styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100vw;
  margin: 8px 0 8px 0;
`
const Imagen=styled.img`
  width: 128px;
  height: 128px;
`
const DivContenido=styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 160px;
`
const StyledP=styled.p`
  text-align: center;
  font-weight: 500;
`
const DescripcionP=styled.p`
  margin: 0px 16px 0px 16px;
`
const Texto=styled.p`
  background-color:${colorTerciario};
  color: ${textoClaro};
  font-weight: 500;
  font-size: 16px;
  padding: 0px 60px;
  margin:280px 0px 0px 0px;
`
const Habitaciones = () => {
  const{currentUser}=useContext(AuthContextStudent)
  const propiedades=currentUser[1]
  const[propiedad, setPropiedad]=useState("")
  let {idPropiedad}=useParams()

  useEffect(()=>{
    propiedades.map((propiedad)=>{
      if(propiedad.idPropiedad==idPropiedad){
        setPropiedad(propiedad)
      }
    })
  },[])

  return (
    <>
      <HeaderLoginStudentAtras urlAtras={`/Propiedades/${propiedad.departamento}`}/>
      <StyledSection>
        <StyledDiv>
          <PEnunciado>Quieres una habitacion? contactate con el propietario</PEnunciado>
          <DivPropiedad>
            <Imagen src={propiedad.imagen} alt='Fotografía de la propiedad'/>
            <DivContenido>
              <StyledP>{propiedad.direccion}</StyledP>
              <DialogContactar celular={propiedad.celOwner}/>
            </DivContenido>
          </DivPropiedad>
          <DescripcionP>{`${propiedad.descripcion}`}</DescripcionP>
          <DialogReglas reglas={propiedad.reglas}/>
        </StyledDiv>
        <Texto>Habitaciones Disponibles</Texto>
        <ListaHabitacionesStudent/>
      </StyledSection>
    </>
  )
}
export default Habitaciones