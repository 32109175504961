import React from 'react'
import styled from 'styled-components'
import { fondo } from '../../../components/UI/variables'
import Titulo1 from "../../../components/Titulo1"
import FormOwnerComplementario from '../../../components/FormOwnerComplementario'
import HeaderLoginOwner from '../../../components/HeaderLoginOwner'
import perroSentado from "../../../assets/imagenes/perroSentado.png"

const StyledSection = styled.section`
  display: flex; 
  flex-direction: column;
  align-items: center;
`
const StyledDiv = styled.div`
  background-color: ${fondo};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`
const StyledTitulo = styled(Titulo1)`
  margin-bottom: 8px;
  position:absolute;
  transform: translate(0%,-500%);
  color: black;
`
const StyledImg=styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 360px;
  height: 336px;
  margin-top: 60px;
`
const CompletarDatos = () => {
  return (
    <StyledSection>
      <HeaderLoginOwner/>
      <StyledImg src={perroSentado} alt='Perro sentado dandote la bienvenida en la puerta'/>
      <StyledDiv >
        <StyledTitulo $naranja>Bienvenido!!! completa tus datos para que los estudiantes te contacten</StyledTitulo>
        <FormOwnerComplementario/>
      </StyledDiv>
    </StyledSection>
  )
}

export default CompletarDatos;