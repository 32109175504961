import React, { useState } from "react";
import styled from "styled-components";
import { colorSecundario } from "../UI/variables";
import BotonSubmit from "../BotonSubmit";
import appFirebase from "../../firebase";
import { getFirestore, updateDoc, doc } from "firebase/firestore";

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  background-color: white;
  background: ${colorSecundario};
  width: 360px;
  height: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;
const DivEncabezadoCard = styled.div`
  display: flex;
  background-color: black;
  height: 80px;
  justify-content: center;
  align-items: center;
  p {
    color: white;
    margin: 8px 0 8px 0;
    font-weight: bold;
  }
`;
const DivDni = styled.div`
  width: 40%;
  padding: 0 8px 0 8px;
`;
const DivNombre = styled.div`
  width: 60%;
  padding: 0 8px 0 8px;
`;
const DivTarjeta = styled.div`
  width: 100%;
  height: 256px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 8px;
`;
const StyledForm = styled.form`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;
const StyledText = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 12px;
  justify-content: center;
  margin: 0px 8px 0px 8px;
  width: 70%;
  height: auto;
  p {
    margin: 8px 0 0 0;
  }
  label {
    height: 24px;
    margin: 8px 0 8px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-weight: bold;
  }
  input {
    margin: 0 0 0 32px;
    padding: 0px 0px;
    width: 32px;
    height: 24px;
    text-align: center;
  }
`;
const StyledPBloqueado = styled.section`
  display: flex;
  align-items: center;
  color: ${(props)=>props.color};
  width: 24px;
  height: auto;
  text-align: center;
  justify-content: center;
  font-weight: bold;
  margin: 0 0 0 0 ;
`
const StyeledDiv = styled.div`
  display: flex;
  justify-content: space-between;
`;
const BotonActivar = styled(BotonSubmit)`
  display: flex;
  flex-direction: column;
  width: 30%;
  height: auto;
  margin: 0;
  border-radius: 8;
`;
const BotonDesactivar = styled(BotonSubmit)`
  display: flex;
  flex-direction: column;
  width: 30%;
  height: auto;
  margin: 0;
  border-radius: 8;
  background-color: gray;
`;
const DivForm = styled.div`
  display: flex;
  margin: 8px 0 0 0;
  padding-top: 8px;
  height: 100px;
  width: 100%;
  border-top: solid 1px black;
`;
const BotonPagar = styled(BotonSubmit)`
  display: flex;
  flex-direction: column;
  width: 30%;
  height: auto;
  margin: 0;
  border-radius: 8;
`;
const PropietarioAdministrado = ({
    limiteHabitaciones, 
    suscripcionActiva, 
    propiedadesOwner, 
    habitacionesOwner, 
    idUsuario, 
    fechaRenovacionSuscripcion, 
    diasTotalAcumulados, 
    fechaInicioSuscripcion, 
    nombre, 
    apellido, 
    planActual, 
    dni, 
    celular, 
    correo,
    bloqueado
  }) => {
  const db = getFirestore(appFirebase);
  const [limitehabitaciones, setLimitehabitaciones] = useState(limiteHabitaciones);
  const [plan, setPlan] = useState("");
  const [procesoEnvioIniciado, setProcesoEnvioIniciado] = useState(false);
  
  const manejarLimiteHabitaciones = (e) => {
    setLimitehabitaciones(e.target.value);
  };
  const manejarPlan = (e) => {
    setPlan(e.target.value);
  };

  const idsPropiedadesPropietario = [];
  propiedadesOwner.map((propiedadOwner) => {
    if (propiedadOwner.idPropietario == idUsuario) {
      idsPropiedadesPropietario.push(propiedadOwner.idPropiedad);
    }
  });
  // Funcion para calcular la diferencia en días entre dos fechas
  const calcularDiferenciaEnDias = (fechaInicio, fechaFin) => {
    const unDiaEnMilisegundos = 1000 * 60 * 60 * 24;
    const diferenciaEnMilisegundos = fechaFin - fechaInicio;
    return Math.ceil(diferenciaEnMilisegundos / unDiaEnMilisegundos);
  };
  //**************************************FUNCION PARA SUMAR DIAS*************************************** */
  const sumarDias = (fecha, dias) => {
    const fechaSumada = new Date(fecha);
    fechaSumada.setDate(fechaSumada.getDate() + dias);
    return fechaSumada;
  }
  const hoy = sumarDias(new Date(),0)
  const diasRestantesSuscripcion = fechaRenovacionSuscripcion&&calcularDiferenciaEnDias (fechaRenovacionSuscripcion.toDate(), hoy);
  const fechaVencimientoSuscripcion = fechaRenovacionSuscripcion&&sumarDias(fechaRenovacionSuscripcion.toDate(),diasTotalAcumulados).toLocaleDateString();

  //*************************FUNCION PARA ACTIVAR Y DESACTIVAR SUSCRIPCION PROPIEDAD******************** */
  const alternarEstadoSuscripcionPropiedades = () => {
    idsPropiedadesPropietario.forEach(async (id) => {
      const datosRegistro = {
        suscripcionActiva: !suscripcionActiva,
      };
      try {
        const documentoRef = doc(db, "propiedades", id);
        await updateDoc(documentoRef, datosRegistro);
      } catch (error) {
        console.error("Error al activar suscripcion propiedad", error);
      }
    });
  };
  //**************************FUNCION PARA ACTIVAR Y DESACTIVAR SUSCRIPCION USUARIO********************* */
  const alternarEstadoSuscripcionUsuario = async () => {
    setProcesoEnvioIniciado(true);
    let datosRegistro;
    if (suscripcionActiva == false) {
      datosRegistro = {
        limiteHabitaciones: limitehabitaciones,
        suscripcionActiva: !suscripcionActiva,
        fechaInicioSuscripcion: fechaInicioSuscripcion ? fechaInicioSuscripcion : new Date(),
        fechaRenovacionSuscripcion: new Date(),
        planSuscripcion: plan,
        diasTotalAcumulados: plan * 30,
      };
    } else {
      datosRegistro = {
        suscripcionActiva: !suscripcionActiva,
      };
    }
    try {
      const documentoRef = doc(db, "usuarios", idUsuario);
      await updateDoc(documentoRef, datosRegistro);
    } catch (error) {
      console.error("Error al activar suscrpcion", error);
    }
    setProcesoEnvioIniciado(false);
  };
  //********************************FUNCION PARA MANEJAR ENVIO DE FORMULARIO*************************** */
  const manejarSubmit = (event) => {
    event.preventDefault();
    if(habitacionesOwner.length <= limitehabitaciones){
      if (window.confirm(`¿Estas seguro de ${suscripcionActiva ? "desactivar" : "activar"} la suscripcion de ${nombre} ${apellido}?`)) {
        alternarEstadoSuscripcionUsuario();
        alternarEstadoSuscripcionPropiedades();
      }
    }else{
      alert("El usuario tiene mas Habs. Registradas que el Limite Habs. que estas asignando")
    }
  };
  //***************************************FUNCION PARA REGISTRAR PAGO********************************* */
  const manejarSubmitPago = async (event) => {
    event.preventDefault();
    setProcesoEnvioIniciado(true);
    const datosRegistro = {
      limiteHabitaciones: limitehabitaciones,
      planSuscripcion: plan,
      diasTotalAcumulados: diasTotalAcumulados + plan * 30,
    };
    if(habitacionesOwner.length <= limitehabitaciones){
      if (window.confirm(`¿Estas seguro de registrar un pago por ${plan} meses y por ${limitehabitaciones} habitaciones?`)) {
        try {
          const documentoRef = doc(db, "usuarios", idUsuario);
          await updateDoc(documentoRef, datosRegistro);
        } catch (error) {
          console.error("Error al registrar pago", error);
        }
        setPlan("");
      }
    }else{
      alert("NO SE PUEDE REGISTRAR EL PAGO, #Habitaciones registradas > Limite de habs.")
    }
    setProcesoEnvioIniciado(false);
  };
  return (
    <StyledSection>
      <DivEncabezadoCard>
        <DivDni>
          <p>DNI: {`${dni}`}</p>
          <p>Cel: {`${celular}`}</p>
        </DivDni>
        <DivNombre>
          <p>{`${nombre} ${apellido}`}</p>
          <p>{`${correo}`}</p>
        </DivNombre>
      </DivEncabezadoCard>

      <DivTarjeta>
        {bloqueado==true && <StyledPBloqueado color="red">BLOQUEADO</StyledPBloqueado>}
        <StyledForm onSubmit={manejarSubmit}>
          <StyledText>
            <StyeledDiv>
              <p><strong>Vence:</strong></p>
              <p>{`${fechaRenovacionSuscripcion ? fechaVencimientoSuscripcion: ""}`}</p>
            </StyeledDiv>
            <StyeledDiv>
              <p><strong>Props Registradas:</strong></p>
              <p>{`${propiedadesOwner.length}`} props.</p>
            </StyeledDiv>
            <StyeledDiv>
              <p><strong>Habs Registradas:</strong></p>
              <p>{`${habitacionesOwner.length}`} habs.</p>
            </StyeledDiv>
            {suscripcionActiva == false ? (
              <label>
                Limite Habs:
                <input
                  type="number"
                  value={limitehabitaciones}
                  onChange={manejarLimiteHabitaciones}
                  required
                />
              </label>
            ) : (
              <StyeledDiv>
                <p><strong>Limite Habs:</strong></p>
                <p>{`${limiteHabitaciones}`} habs.</p>
              </StyeledDiv>
            )}
            {suscripcionActiva == false ? (
              <label>
                Plan (meses):
                <input
                  type="number"
                  value={plan}
                  onChange={manejarPlan}
                  required
                />
              </label>
            ) : (
              <StyeledDiv>
                <p><strong>Plan Actual:</strong></p>
                <p>{`${planActual}`} meses</p>
              </StyeledDiv>
            )}
          </StyledText>
          {suscripcionActiva == false ? (
            bloqueado==false && <BotonActivar $procesoenvioiniciado={procesoEnvioIniciado} disabled={procesoEnvioIniciado ? true : false}>Activar</BotonActivar>
          ) : (
            <BotonDesactivar $procesoenvioiniciado={procesoEnvioIniciado} disabled={procesoEnvioIniciado ? true : false}>Desactivar</BotonDesactivar>
          )}
        </StyledForm>
        {suscripcionActiva == true && diasRestantesSuscripcion<diasTotalAcumulados ? (
          <DivForm>
            <StyledForm onSubmit={manejarSubmitPago}>
              <StyledText>
                <label>
                  Limite Habs:
                  <input
                    type="number"
                    value={limitehabitaciones}
                    onChange={manejarLimiteHabitaciones}
                    required
                  />
                  rooms
                </label>
                <label>
                  Plan:
                  <input
                    type="number"
                    value={plan}
                    onChange={manejarPlan}
                    required
                  />
                  meses
                </label>
              </StyledText>
              <BotonPagar $procesoenvioiniciado={procesoEnvioIniciado} disabled={procesoEnvioIniciado ? true : false}>Registrar Pago</BotonPagar>
            </StyledForm>
          </DivForm>
        ) : (
          <></>
        )}
      </DivTarjeta>
    </StyledSection>
  );
};

export default PropietarioAdministrado;
