import React, { useEffect, useState, useContext } from "react";
import { AuthContextOwner } from "../../context/AuthOwner";
import HabitacionAdministradaPagos from "../HabitacionAdministradaPagos";
import GraficoBarraHorizontal from "../GraficoBarraHorizontal";
import styled from "styled-components";
import { colorPrimario, colorSecundario, fondo, textoClaro } from "../UI/variables";

const StyledSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${fondo};
  align-items: center;
  margin-top: 16px;
`;
const StyledDialog = styled.dialog`
  border-radius: 10px;
  border: 1px solid ${colorPrimario};
  align-items: center;
  width: 312px;
  height: auto;
  position: fixed;
  top: 25%;
  z-index: 1001; /* Debe estar por encima del overlay */
`;
const DivFondo = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1000;
`
const BotonCardLista = styled.button`
  display: flex;
  border: none;
  background-color: ${colorSecundario};
  margin-bottom: 16px;
  padding: 0;
  width: 360px;
  &:hover {
    border: solid 2px black;
    font-size: 20px;
  }
  &:active {
    border: solid 2px black;
    font-size: 20px;
  }
`;
const DivHabitacion = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 104px;
  background-color: ${colorPrimario};
  color: white;
  font-weight: bold;
  margin-right: 8px;
  font-size: 20px;
`;
const DivDatos = styled.div`
  width: 240px;
  height: 104px;
  h1 {
    height: 16px;
    margin: 8px 0 8px 0;
  }
`;
const StyledDivPagado = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 104px;
  margin: 0 0 0 8px;
  color: ${textoClaro};
  font-weight: bold;
  background-color: green;
  p{
    margin: 8px 0 0 0;
    font-size: 20px;
    color: ${textoClaro};
  }
`;
const StyledDivDebe = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 104px;
  margin: 0 0 0 8px;
  color: ${textoClaro};
  font-weight: bold;
  background-color: red;
  p{
    margin: 8px 0 0 0;
    font-size: 20px;
    color: ${textoClaro};
  }
`;
const DivFechas = styled.div`
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  p {
    height: 16px;
    margin: 0;
    text-align: center;
    font-size: 12px;
  }
`;
const StyledP = styled.p`
  color: ${colorPrimario};
  font-weight: 500;
  text-align: center;
`;
const BotonCerrar = styled.button`
  background-color: ${colorPrimario};
  font-weight:bold;
  font-size: 24px;
  border-radius: 20px;
  border: none;
  position: absolute;
  top: 5px;
  right: 5px;
  width: 40px;
  height: 40px;
  color:white;
`
const ListaHabitacionesAdministradasPagos = ({ contarHabitaciones, propiedadSeleccionada }) => {
  const idPropiedad = propiedadSeleccionada.idPropiedad;
  const { currentUser } = useContext(AuthContextOwner);
  const habitacionesOwner = currentUser[2];
  const [lista, setLista] = useState([]);
  const [habitacionSeleccionada, setHabitacionSeleccionada] = useState(null);

  const [fechaActual, setfechaActual] = useState("");
  const [isOpen, setIsOpen] = useState(false)
  //**************************FUNCION OBTENER LA FECHA ACTUAL*************************** */
  useEffect(() => {
    const fecha = new Date();
    setfechaActual(fecha)
    //para probar modificando fecha actual
    // setfechaActual(sumarDias(fecha, 61));
  }, []);

  const habitacionesPropiedad = [];
  habitacionesOwner.map((habitacionOwner) => {
    if (habitacionOwner.idPropiedad == idPropiedad) {
      habitacionesPropiedad.push(habitacionOwner);
    }
  });
  useEffect(() => {
    setLista(habitacionesPropiedad);
  }, [propiedadSeleccionada, habitacionesOwner, currentUser]);

  //ordenamos las habitaciones dentro del array lista
  lista.sort(function (a, b) {
    return a.NroHabitacion - b.NroHabitacion;
  });
  //contamos las habitaciones dentro del array lista
  useEffect(() => {
    contarHabitaciones(lista.length);
  }, [lista]);
  const habitacionesNoDisponibles = lista.filter((list) => list.disponible === false);

  // Calcula la diferencia en días entre dos fechas
  const calcularDiferenciaEnDias = (fechaInicio, fechaFin) => {
    const unDiaEnMilisegundos = 1000 * 60 * 60 * 24;
    const diferenciaEnMilisegundos = fechaFin - fechaInicio;
    return Math.ceil(diferenciaEnMilisegundos / unDiaEnMilisegundos);
  };
  //**********************FUNCION PARA TRANSFORMAR DIAS A (X MESES, Y DIAS)******************* */
  const diasAMesesYDias = (cantidadDias) => {
    const meses = Math.floor(cantidadDias / 30);
    const dias = cantidadDias % 30;
    return `${meses} meses y ${dias} días`;
  };
  //*****************************FUNCION PARA SUMAR DIAS A UNA FECHA***************************** */
  const sumarDias = (fecha, dias) => {
    const fechaSumada = new Date(fecha);
    fechaSumada.setDate(fechaSumada.getDate() + dias);
    return fechaSumada;
  };
  //***************************FUNCION PARA SUMAR X PERIODOS DE 30 DIAS A UNA FECHA************************* */
  const sumarPeriodos = (fecha, numeroPeriodos) => {
    const diasASumar = numeroPeriodos * 30;
    const fechaResultante = new Date(fecha);
    fechaResultante.setDate(fechaResultante.getDate() + diasASumar);
    return fechaResultante;
  };
  //********************************FUNCION PARA OBTENER PERIODO ACTUAL***************************** */
  const calcularPeriodoActual = (fechaEntrada) => {
    let periodoActual;
    const calculoPeriodoActual = Math.ceil(calcularDiferenciaEnDias(fechaEntrada, fechaActual) / 30);
    if (calculoPeriodoActual > 0) {
      periodoActual = calculoPeriodoActual;
    } else {
      periodoActual = 1;
    }
    return periodoActual;
  };
  //***************************CALCULAR FECHA DE INICIO DE PERIODO ACTUAL****************************** */
  const calcularFechaInicioPeriodoActual = (fechaEntrada) => {
    let periodoActual = calcularPeriodoActual(fechaEntrada);
    let periodosASumar = periodoActual - 1;
    let diasASumar = periodosASumar * 30;

    const fechaInicioPeriodoActual = sumarDias(fechaEntrada, diasASumar);
    return fechaInicioPeriodoActual;
  };
  //***************************CALCULAR FECHA DE FIN DE PERIODO ACTUAL****************************** */
  const calcularFechaFinPeriodoActual = (fechaEntrada) => {
    const fechaFinPeriodoActual = sumarDias(sumarPeriodos(fechaEntrada, calcularPeriodoActual(fechaEntrada)), -1);
    return fechaFinPeriodoActual;
  };

  const handleClose = () => {
    setIsOpen(false);
  };
  const abrirDialog = ()=>{
    setIsOpen(true)
  }
  const manejarOnClick = (i) => {
    setHabitacionSeleccionada(i);
    abrirDialog()
  };

  return (
    <StyledSection>
      {habitacionesNoDisponibles.length > 0 && habitacionesPropiedad.length > 0 ? (
        lista.map((list, i) =>
        list.disponible == false ? (
          <BotonCardLista key={list.idHabitacion} onClick={() => manejarOnClick(i)}>
            <DivHabitacion>{`H${list.NroHabitacion}`}</DivHabitacion>
            <DivDatos>
              <h1>Estadia: {diasAMesesYDias(calcularDiferenciaEnDias(list.fechaEntrada.toDate(), fechaActual))}</h1>
              <GraficoBarraHorizontal
                limiteDias={30}
                diasTranscurridos={calcularDiferenciaEnDias(
                  calcularFechaInicioPeriodoActual(list.fechaEntrada.toDate()),
                  fechaActual
                )}
              />
              <DivFechas>
                <div>
                  <p>Inicio</p>
                  <p>{calcularFechaInicioPeriodoActual(list.fechaEntrada.toDate()).toLocaleDateString()}</p>
                </div>
                <p>Perido Actual</p>
                <div>
                  <p>Fin</p>
                  <p>{calcularFechaFinPeriodoActual(list.fechaEntrada.toDate()).toLocaleDateString()}</p>
                </div>
              </DivFechas>
            </DivDatos>
            {calcularPeriodoActual(list.fechaEntrada.toDate()) <= list.periodoPagado ? (
              <StyledDivPagado>
                Pagado
                <p>{list.periodoPagado}</p>
                {list.periodoPagado == 1 ? "mes" : "meses"}
              </StyledDivPagado>
            ) : (
              <StyledDivDebe>
                Debe
                <p>{calcularPeriodoActual(list.fechaEntrada.toDate()) - list.periodoPagado}</p>
                {calcularPeriodoActual(list.fechaEntrada.toDate()) - list.periodoPagado == 1 ? "mes" : "meses"}
              </StyledDivDebe>
            )}
          </BotonCardLista>
        ) : (
          <></>
        )
        )
      ) : (
        <StyledP>Aun no existen habitaciones con estudiantes registrados.</StyledP>
      )}
      {isOpen==true ? <DivFondo onClick={handleClose}/> :<></>}
      {isOpen==true && 
      <StyledDialog open={isOpen} onClose={handleClose}>
        {habitacionSeleccionada != null && 
          <HabitacionAdministradaPagos
            imagen={lista[habitacionSeleccionada].Imagen}
            nroHabitacion={lista[habitacionSeleccionada].NroHabitacion}
            idHabitacion={lista[habitacionSeleccionada].idHabitacion}
            disponible={lista[habitacionSeleccionada].disponible}
            dni={lista[habitacionSeleccionada].dni}
            precio={lista[habitacionSeleccionada].Precio}
            nombreCompleto={lista[habitacionSeleccionada].nombreCompleto}
            celular={lista[habitacionSeleccionada].celular}
            fechaEntrada={lista[habitacionSeleccionada].fechaEntrada}
            periodoActual={calcularPeriodoActual(lista[habitacionSeleccionada].fechaEntrada.toDate())}
            periodoPagado={lista[habitacionSeleccionada].periodoPagado}
            fechaInicioPeriodoPagado={lista[habitacionSeleccionada].fechaInicioPeriodoPagado}
            fechaFinPeriodoPagado={lista[habitacionSeleccionada].fechaFinPeriodoPagado}
          />
        }
        <BotonCerrar onClick={handleClose}>X</BotonCerrar>
      </StyledDialog>
      }
    </StyledSection>
  );
};

export default ListaHabitacionesAdministradasPagos;
