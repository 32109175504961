import React, { useState, useCallback } from "react";
import styled from "styled-components";
import Entrada from "../Entrada";
import Desplegable from "../Desplegable";
import BotonSubmit from "../BotonSubmit";
import appFirebase from "../../firebase";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import { getAuth, signOut, createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  margin: 16px 16px 16px 16px;
  flex-wrap: wrap;
`;
const PInstruccion = styled.p`
  margin: 0px 0px 8px 0px;
  font-size: 12px;
  width: 328px;
`;
const StyledP = styled.p`
  margin: -8px 0px 8px 0px;
  font-size: 12px;
`;
const StyledDiv = styled.div`
  display: flex;
  width: 328px;
  margin-top: 8px;
  margin-bottom: 8px;
  text-align: center;
`;
const PTyC = styled.p`
  display: block  ;
  color: blue;
  font-size: 12px;
  margin-bottom: 16px;
  text-align: center;
  text-decoration: underline;
`;
const auth = getAuth(appFirebase);
const db = getFirestore(appFirebase);

const FormOwner = () => {
  const navigate = useNavigate();
  const [nombre, setNombre] = useState("");
  const [correo, setCorreo] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [ubicacion, setUbicacion] = useState("");
  const [aceptacionTyC, setAceptacionTyC] = useState(false);
  const [procesoEnvioIniciado, setProcesoEnvioIniciado] = useState(false);
  const [requisito, setRequisito] = useState(false);

  const mostrarRequisito = () => {
    setRequisito(true);
  };

  const manejarNombre = (e) => {
    setNombre(e.target.value);
  };
  const manejarCorreo = (e) => {
    setCorreo(e.target.value);
  };
  const manejarPassword = (e) => {
    setPassword(e.target.value);
  };
  const manejarConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    setPasswordMatch(e.target.value === password);
  };
  const manejarDepartamento = (valor) => {
    setUbicacion(valor);
  };
  const manejarAceptacionTyC = (e) => {
    setAceptacionTyC(e.target.checked);
  };
  const confirmarInicioDeEnvio = () => {
    setProcesoEnvioIniciado(true);
  };
  const hoy = new Date();

  const abrirTyC = () => {
    window.open('/TyC', '_blank');
  };

  const manejarEnvio = useCallback(
    async (e) => {
      e.preventDefault();
      confirmarInicioDeEnvio();

      if (passwordMatch == true) {
        const newRegistro = {
          nombre: nombre,
          correo: correo,
          ubicacion: ubicacion,
          rol: "propietario",
          uuid: uuidv4(),
          suscripcionActiva: false,
          planSuscripcion: "",
          limiteHabitaciones: "",
          diasTotalAcumulados: 0,
          dni: "",
          bloqueado: false,
          aceptacionTyC: aceptacionTyC,
          fechaAceptacionTyC: hoy
        };

        try {
          //se crea un usuario en Autentication de firebase
          const infoUsuario = await createUserWithEmailAndPassword(auth, correo, password).then((usuarioFirebase) => {
            return usuarioFirebase;
          });
          //obtenemos el id de usuario creado en Autentication
          const idUsuario = infoUsuario.user.uid;
          //en Database creamos un documento con el mismo id del Autentication y enviamos los datos a database
          const docuRef = doc(db, `usuarios/${idUsuario}`);
          await setDoc(docuRef, { ...newRegistro });

          const configuracion = {
            url: process.env.NODE_ENV === "production"
              ? "https://www.arkilados.com/" // URL en producción
              : "http://localhost:3000"      // URL en desarrollo
          };

          await sendEmailVerification(auth.currentUser, configuracion);

          await signOut(auth);

          //navigate("/Contacto");
          window.location.href = "/Contacto";
        } catch (error) {
          alert("Se ha producido un error en tu registro, revisa tus datos y vuelve a intentarlo.");
        }
      }
    },
    [navigate, correo, password, ubicacion, nombre, aceptacionTyC]
  );

  return (
    <div>
      <StyledForm onSubmit={manejarEnvio}>
        <Entrada type="text" value={nombre} onChange={manejarNombre} placeholder="Nombres y Apellidos" required />
        <Entrada type="email" value={correo} onChange={manejarCorreo} placeholder="Correo" required />
        <Entrada
          type="password"
          value={password}
          onChange={manejarPassword}
          onFocus={mostrarRequisito}
          placeholder="Contraseña"
          required
          pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[a-zA-Z\d\W_]{6,}$"
          title="Al menos 6 caracteres, debe contener una letra minuscula, una letra mayuscula, un numero y un caracter especial."
        />
        {requisito && (
          <PInstruccion>
            Debe tener al menos 6 caracteres, 1 mayúscula, 1 minúscula, un número y un símbolo (ej: !@#$%)
          </PInstruccion>
        )}
        <Entrada
          type="password"
          value={confirmPassword}
          onChange={manejarConfirmPassword}
          placeholder="Confirmar contraseña"
          required
          pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[a-zA-Z\d\W_]{6,}$"
          title="La contraseña no coincide"
        />
        {!passwordMatch && (
          <StyledP style={{ color: "red" }}>Las contraseñas no coinciden. Intentalo de nuevo.</StyledP>
        )}
        <Desplegable onSeleccionChange={manejarDepartamento} />
        <StyledDiv>
          <input value={aceptacionTyC} onChange={manejarAceptacionTyC}id="vinculo" type="checkbox" required title="Debes aceptar los terminos y condiciones para continuar"/>
          <label htmlFor="vinculo">
            Acepto los terminos de servicio y politicas de privacidad
          </label>
        </StyledDiv>
        <PTyC onClick={abrirTyC}>Ver Terminos y Politicas</PTyC>
        <BotonSubmit $large $procesoenvioiniciado={procesoEnvioIniciado} disabled={procesoEnvioIniciado ? true : false}>
          {procesoEnvioIniciado ? "Creando tu cuenta... ⌛⌛" : "Empieza a Administrar"}
        </BotonSubmit>
      </StyledForm>
    </div>
  );
};

export default FormOwner;
