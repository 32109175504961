import styled from "styled-components";
import { textoOscuro } from "./UI/variables";

const AreaTexto = styled.textarea`
  width: 306px;
  height: 88px;
  padding: 10px;
  border-radius: 5px;
  border-color: ${textoOscuro};
  resize: none;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export default AreaTexto;
