import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { fondo, colorPrimario } from '../../../components/UI/variables'
import mascota from "../../../assets/imagenes/mascota.png"

import Titulo2 from "../../../components/Titulo2"
import BoxContacto from "../../../components/BoxContacto"
import Boton from "../../../components/Boton"
import HeaderLoginOwner from '../../../components/HeaderLoginOwner'

const StyledSection = styled.section`
  padding-top: 8px;
  background-color: ${fondo};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`
const StyleDiv = styled.div`
  margin-bottom: 180px;
`
const StyleDivInstrucciones = styled.div`
  font-size: 14px;
  margin: 0 16px 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: start;
`
const StyleP = styled.p`
  font-size: 16px;
`
const StyledImg = styled.img`
  height: 128px;
`
const StyledBoton=styled(Boton)`
  width: 328px;
  background-color: ${fondo};
  color: ${colorPrimario};
  border: 1px solid ${colorPrimario};
`
const Contacto = ({usuarioActual}) => {
  const [usuarioActualConfirmado, setUsuarioActualConfirmado] = useState(false)
  useEffect(()=>{
    if(usuarioActual){
      setUsuarioActualConfirmado(true)
    }
  },[usuarioActual])
  return (
    <div>
      {usuarioActualConfirmado ? <HeaderLoginOwner/> : <></>}
      <StyledSection>
        <StyleDiv>
          <Titulo2 $naranja>Bienvenido</Titulo2>
          <StyleDivInstrucciones>
            <StyleP><strong> 1.</strong> Ponte en contacto con nosotros para adquirir un plan para registrar tus habitaciones</StyleP>
            <BoxContacto/>
            <StyleP><strong> 2.</strong> Si ya adquiriste tu plan y verificaste tu correo, dirigete a la pagina principal para iniciar sesion y toma el control de tus alquileres!!!! </StyleP>
            <StyleP><strong> Nota: </strong> Para verificar tu cuenta, haz click en el link que enviamos a tu correo electronico y listo. No olvides revisar en Spam o no deseados </StyleP>
            <StyledImg src={mascota} alt='Mascota de Arkilados - Perro Cuartelero'/>
            <StyledBoton to="/">Ir a pagina principal </StyledBoton>
          </StyleDivInstrucciones>
        </StyleDiv>
      </StyledSection>
    </div>
  )
}

export default Contacto