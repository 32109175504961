import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { fondo } from '../../../components/UI/variables'

import Titulo2 from "../../../components/Titulo2"
import AddTarjetaPropiedad from "../../../components/AddTarjetaPropiedad"
import ListaPropiedadesRegistradasOwner from "../../../components/ListaPropiedadesRegistradasOwner"
import Boton from '../../../components/Boton'

const StyledSection = styled.section`
  background-color: ${fondo}; 
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  height: 100%;
`
const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  height: 40px;
  width: 96vw;
  align-items: center;
`
const TituloStyled= styled(Titulo2)`
  width: 100%;
  text-align: center;
`
const StyledP = styled.p`
  margin: 8px 0;
`
const AgregarPropiedades = () => {
  return (
    <StyledSection>
      <StyledHeader>
        <Boton to="/HomeOwnerLogin">Inicio</Boton>
        <TituloStyled $naranja>Propiedades</TituloStyled>
      </StyledHeader>
      <StyledP>1. Para <strong>agregar nuevas Propiedades</strong>, presione el botón "Agregar una propiedad".</StyledP>
      <Link to="/RegistroPropiedad">
        <AddTarjetaPropiedad/>
      </Link>
      <ListaPropiedadesRegistradasOwner/>
    </StyledSection>
  )
}

export default AgregarPropiedades