import styled from "styled-components";
import { textoOscuro } from "./UI/variables";

const Titulo1 = styled.h1`
  font-size: 16px;
  font-weight: bold;
  text-shadow: -1px -1px 2px white, 1px -1px 2px white, -1px 1px 2px white,
    1px 1px 2px white;
  color: ${textoOscuro};
  text-align: center;
  width: 296px;
  height: 60px;
`;

export default Titulo1;
