import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import BotonEditar from "../BotonEditar";
import { colorSecundario } from "../UI/variables";
import editar from "../../assets/iconos/editar.svg";
import eliminar from "../../assets/iconos/eliminar.svg";
import BotonEliminar from "../BotonEliminar";
import appFirebase from '../../firebase'
import { getStorage,ref,deleteObject } from 'firebase/storage'
import { deleteDoc, doc, getFirestore, updateDoc} from 'firebase/firestore'
import { AuthContextOwner } from '../../context/AuthOwner'
import { useNavigate } from "react-router-dom";

const StyledSection = styled.section`
  width: 360px;
  height: 112px;
  background-color: ${colorSecundario};
  display: flex;
  justify-content: space-around;
  box-sizing: border-box;
  padding: 8px;
  margin: 8px 0px;
`;
const StyledDiv = styled.div`
  width: 160px;
`;
const StyledImg = styled.img`
  width: 168px;
  height: 96px;
  object-fit: cover;
`;
const StyledDivTitulo = styled.div`
  display: flex;
  flex-direction: column;
`;
const StyledP = styled.p`
  font-weight: 500;
  margin: 0px 0px 0px 0px;
  text-align: center;
  font-weight: 500;
`;
const StyledPrecio = styled.p`
  font-weight: 400;
  color: green;
  margin: 8px 0px 8px 0px;
  text-align: center;
  font-weight: 500;
`;

const ContenedorBotones = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Icon = styled.img`
  background-color: transparent;
  fill: white;
`;

const HabitacionRegistrada = (props) => {
  const db = getFirestore(appFirebase);
  const storage = getStorage(appFirebase);
  const navigate = useNavigate()
  const { currentUser } = useContext(AuthContextOwner);
  const habitacionesPropiedad=props.habitacionesPropiedad
  const [disponibilidadPropiedad, setDisponibilidadPropiedad] = useState("")

  const habitacionesDisponibles = []
  habitacionesPropiedad.map((habitacion)=>{
    if(habitacion.disponible){
      habitacionesDisponibles.push(habitacion)
    }
  })
  let totalHabitacionesDisponibles = habitacionesDisponibles.length


  // Obtenemos idHabitacion, idPropiedad, uuidHabitacion mediante props
  const idHabitacion=props.idHabitacion
  const idPropiedad=props.idPropiedad
  const uuidHabitacion = props.uuidHabitacion
  const disponible=props.disponible
  const preciosHabitacionesEnviar = props.preciosHabitacionesEnviar

  //Obtenemos uuidPropiedad de propiedades traidas desde contexto
  const propiedades = currentUser[1];
  const [uuidPropiedad, setUuidPropiedad] = useState("");
  useEffect(() => {
    propiedades.map((propiedad) => {
      if (propiedad.idPropiedad === idPropiedad) {
        setUuidPropiedad(propiedad.uuidPropiedad);
      }
    });
  }, [idPropiedad]);

  useEffect(()=>{
    if(disponible === true){
      if(totalHabitacionesDisponibles==1){
        setDisponibilidadPropiedad(false)
      }else if(totalHabitacionesDisponibles>1){
        setDisponibilidadPropiedad(true)
      }
    }else if(disponible === false){
      if(totalHabitacionesDisponibles>=1){
        setDisponibilidadPropiedad(true)
      }else if(totalHabitacionesDisponibles<=0){
        setDisponibilidadPropiedad(false)
      }
    }
  },[totalHabitacionesDisponibles, habitacionesPropiedad, disponible])

  // funcion para actualizar el valor de disponibilidadPropiedad
  const modificarDatosPropiedad = async(idPropiedad)=>{
    const datosModificadosPropiedad = {
      disponibilidadPropiedad: disponibilidadPropiedad,
      preciosHabitaciones: preciosHabitacionesEnviar,
    };
    try {
      const documentoRef = doc(db, "propiedades",idPropiedad);
      await updateDoc(documentoRef, datosModificadosPropiedad);
    } catch (error) {
      console.error("Error al modificar disponibilidadPropiedad:", error);
    }
  }
  //Funcion para borrar datos y archivos de la habitacion usando uuidPropiedad y uuidHabitacion
  const eliminarHabitacion=async(id)=>{
    if(disponible === true){
      if(window.confirm("¿Estas seguro de eliminar esta habitacion?")){
        try{
          await deleteDoc(doc(db, "habitaciones", id ))
            const refImagen=ref(storage,`usuarios/propietarios/${currentUser[0].uid}/propiedades/${uuidPropiedad}/habitaciones/${uuidHabitacion}/imagenesHabitacion/${uuidPropiedad}-${uuidHabitacion}-imagen1`)
            await deleteObject(refImagen)
          }catch(error){
            console.log(error)
          }
        modificarDatosPropiedad(idPropiedad)
      }
    }else{
      alert("No se puede eliminar esta habitacion por que existe un estudiante registrado en ella.")
    }
  }
  //Funcion para llevarnos a la pagina EditarHabitacion
  const editarHabitacion=async()=>{
    if(disponible === true){
      navigate(`/EditarHabitacion/${props.idPropiedad}/${props.idHabitacion}`)
    }else{
      alert("No se puede editar esta habitacion ocupada")
    }
  }
  return (
    <StyledSection>
      <StyledImg src={props.imagen} alt="Fotografia de habitacion registrada" />
      <StyledDiv>
        <StyledDivTitulo>
          <StyledP>{`Habitacion ${props.nroHabitacion}`}</StyledP>
          <StyledPrecio>{`S/. ${props.precio}`}</StyledPrecio>
        </StyledDivTitulo>
        <ContenedorBotones>
          <BotonEliminar onClick={()=>eliminarHabitacion(idHabitacion)}>
            <Icon src={eliminar} alt="Eliminar"/>
          </BotonEliminar>
          <BotonEditar onClick={()=>editarHabitacion()}>
            <Icon src={editar} alt="Editar"/>
            Editar
          </BotonEditar>
        </ContenedorBotones>
      </StyledDiv>
    </StyledSection>
  );
};

export default HabitacionRegistrada;
