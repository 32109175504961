//Gris claro
export const fondo = "#F4F4F4";

//Naranja marca
export const colorPrimario = "#FF7901";

//Gris oscuro marca
export const colorTerciario = "#7F7F7F";

//Gris medio cards
export const colorSecundario = "#D9D9D9";

//Naranja tarjetas
export const colorTarjeta = "#FA9840";

//Amarillo hover
export const tapBotones = "#FF7901";

//Gris claro Texto
export const textoClaro = "#F2F2F2";

//Gris oscuro Texto
export const textoOscuro = "#474747";
