import styled from "styled-components";
import { textoClaro, textoOscuro } from "../UI/variables";

const StyledDiv = styled.div`
  box-sizing: border-box;
  width: 360px;
  height: 112px;
  display: flex;
  margin: 0px 0px 8px 0px;
  justify-content: center;
  background-color: ${textoOscuro};
`;

const Imagen = styled.img`
  width: 112px;
  height: 96px;
  margin: 8px;
  object-fit: cover;
`;

const Contenido = styled.div`
  margin: 8px;
  width: 160px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Contador = styled.p`
  font-size: 24px;
  font-weight: bold;
  color: ${textoClaro};
  margin: 8px 0 8px 0 ;
`;

const Habitaciones = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  color: ${textoClaro};
`;
const ContadorHabitaciones = (props) => {
  return (
    <StyledDiv>
      <Imagen src={props.imagen} alt="Fotografia de la propiedad seleccionada"/>
      <Contenido>
        <Contador>{`0${props.totalHabitaciones}`}</Contador>
        <Habitaciones>Habitaciones</Habitaciones>
      </Contenido>
    </StyledDiv>
  );
};

export default ContadorHabitaciones;
