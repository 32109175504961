import { getAuth, sendPasswordResetEmail, rese } from 'firebase/auth'
import React, { useState } from 'react'
import appFirebase from '../../firebase'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { colorPrimario } from '../../components/UI/variables'
import BotonSubmit from '../../components/BotonSubmit'

const StyledSection = styled.section`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 240px;
`
const StyledH1 = styled.h1`
    color: ${colorPrimario};
    margin-bottom: 32px;
`
const StyledForm = styled.form`
    width: 328px;
    display: flex;
    flex-direction: column;
    label{
        font-weight: bold;
    }
    input{
        margin: 0 0 16px 0;
        height: 24px;
    }
    p{
        font-size: 12px;
    }
`
const ResetPass = () => {
    const auth = getAuth(appFirebase);
    const navigate=useNavigate()
    const [correo, setCorreo] = useState("")

    const manejarCorreo = (e)=>{
        setCorreo(e.target.value)
    }


    const manejarRestablecimiento = async(e)=>{
        e.preventDefault()
        
        sendPasswordResetEmail(auth, correo).then(data=>{
            alert("Revisa tu email para cambiar tu contraseña")
            navigate("/SignIn")
        }).catch(error=>{
            alert("Ha ocurrido un error, vuelve a intentarlo", error)
        })
    }
  return (
    <StyledSection>
        <StyledH1>Olvidaste tu contraseña?</StyledH1>
        <StyledForm onSubmit={manejarRestablecimiento}>
            <label>Escribe tu correo electronico:</label>
            <input type='email' value={correo} onChange={manejarCorreo}/>
            <p>*Revise bien que su correo sea el correcto, se le enviará un link para que pueda restablecer su contraseña.</p>
            <BotonSubmit $large >Restablecer contraseña</BotonSubmit>
        </StyledForm>
    </StyledSection>
  )
}

export default ResetPass