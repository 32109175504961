import React from 'react';
import styled from 'styled-components';

const BarraContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 24px;
  background-color: #f0f0f0;
  border-radius: 5px;
`;

const BarraDiasTranscurridos = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #92D050;
  border-radius: 5px 0 0 5px;
  width: ${(props) => props.width}%;
`;
const BarraDiasRestantes = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 0 5px 5px 0;
  width: ${(props) => props.width}%;
`;
const GraficoBarraHorizontal = ({ limiteDias, diasTranscurridos }) => {
  const diasRestantes = limiteDias - diasTranscurridos;
  const progresoDiasTranscurridos = (diasTranscurridos / limiteDias) * 100;
  const progresoDiasRestantes = (diasRestantes / limiteDias) * 100;

  return (
    <BarraContainer>
      <BarraDiasTranscurridos width={progresoDiasTranscurridos}>
        <p>{diasTranscurridos}</p>
      </BarraDiasTranscurridos>
      <BarraDiasRestantes width={progresoDiasRestantes}>
        <p>{diasRestantes}</p>
      </BarraDiasRestantes>
    </BarraContainer>
  );
}

export default GraficoBarraHorizontal;