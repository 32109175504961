import React, { useEffect, useState, useContext } from "react";
import CardHabitacion from "../CardHabitacion";
import styled from "styled-components";
import { AuthContextStudent } from "../../context/AuthStudent";

const StyledDiv = styled.div`
  margin-top: 8px;
`;

const ListaHabitacionesStudent = () => {
  const { currentUser } = useContext(AuthContextStudent);
  const [lista, setLista] = useState([]);
  const habitaciones = currentUser[2];

  const habitacionesDisponiblesPropiedad = [];
  habitaciones.map((habitacion) => {
    if ( habitacion.disponible === true ) {
      habitacionesDisponiblesPropiedad.push(habitacion);
    }
  });

  useEffect(() => {
    setLista(habitacionesDisponiblesPropiedad);
  }, [habitaciones]);

  //ordenamos las habitaciones dentro del array lista
  lista.sort(function (a, b) {
    return a.NroHabitacion - b.NroHabitacion;
  });
  
  return (
    <StyledDiv>
      {lista.map((list, i) => (
        <CardHabitacion
          key={i}
          imagen={list.Imagen}
          descripcion={list.Descripcion}
          nroHabitacion={list.NroHabitacion}
          precio={list.Precio}
          sillas={list.Sillas==="" ? 0 : list.Sillas}
          camas={list.Camas==="" ? 0 : list.Camas}
          armarios={list.Armarios==="" ? 0 : list.Armarios}
          mesas={list.Mesas==="" ? 0 : list.Mesas}
          veladores={list.Veladores==="" ? 0 : list.Veladores}
          banhoPrivado={list.banhoPrivado}
          banhoCompartido={list.banhoCompartido}
          aguaFria={list.duchaFria}
          aguaCaliente={list.duchaCaliente}
          internet={list.internet}
          tvCable={list.tvCable}
          accesoTendedero={list.accesoTendedero}
          ventilacion={list.ventilador}
          calefaccion={list.calefaccion}
          area={list.Area}
        />
      ))}
    </StyledDiv>
  );
};

export default ListaHabitacionesStudent;
