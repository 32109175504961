import styled from "styled-components";
import { colorSecundario, colorPrimario, textoClaro, colorTerciario } from "../UI/variables";
import inicio from "../../assets/iconos/inicio.svg";
import suscripcion from "../../assets/iconos/suscripcion.svg";
import instructivo from "../../assets/iconos/instructivo.svg";
import campana from "../../assets/iconos/campana.png";

import { NavLink } from "react-router-dom";
import { useContext } from "react";
import { AuthContextOwner } from "../../context/AuthOwner";

const StyledNav = styled.nav`
  position: fixed;
  bottom: 0;
  height: 72px;
  display: flex;
  align-items: center;
`;
const StyledUl = styled.ul`
  background-color: ${colorSecundario};
  width: 100vw;
  height: 72px;
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
`;
const StyledLi = styled.li`
  width: 33.3%;
`;
const StyledNavLink = styled(NavLink)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 8px 0px;
  border: solid 1px ${textoClaro};
  text-decoration: none;
  font-size: 14px;
  margin: 0;
  img {
    width: 32px;
    height: 32px;
  }
  &:hover {
    background-color: ${colorTerciario};
    color: ${textoClaro};
    img {
      filter: brightness(4);
    }
  }
  &.active {
    background-color: ${colorPrimario};
    color: ${textoClaro};
    img {
      filter: brightness(4);
    }
  }
`;
const StyledDiv = styled.div`
  height: 32px;
`;
const ImgCampana = styled.img`
  position: absolute;
  transform: translate(40%, -30%);
`;

const NavigationTab = () => {
  const { currentUser } = useContext(AuthContextOwner);
  const diasTotalAcumulados = currentUser[0].diasTotalAcumulados;
  const fechaRenovacionSuscripcion = currentUser[0].fechaRenovacionSuscripcion.toDate();
  const hoy = new Date();

  // *****************Calcula la diferencia en días entre dos fechas**********************
  const calcularDiferenciaEnDias = (fechaInicio, fechaFin) => {
    const unDiaEnMilisegundos = 1000 * 60 * 60 * 24;
    const diferenciaEnMilisegundos = fechaFin - fechaInicio;
    return Math.ceil(diferenciaEnMilisegundos / unDiaEnMilisegundos);
  };
  const diasTranscurridos = calcularDiferenciaEnDias(fechaRenovacionSuscripcion, hoy);
  const diasFaltantes = diasTotalAcumulados - diasTranscurridos;

  return (
    <StyledNav>
      <StyledUl>
        <StyledLi>
          <StyledNavLink to="/HomeOwnerLogin" activeclassname="active">
            <img src={inicio} alt="Inicio" />
            Inicio
          </StyledNavLink>
        </StyledLi>
        <StyledLi>
          <StyledNavLink to="/SuscripcionOwner" activeclassname="active">
            <StyledDiv>
              <img src={suscripcion} alt="Suscripcion" />
              {diasFaltantes <= 3 && <ImgCampana src={campana} alt="Suscripcion" />}
            </StyledDiv>
            Mi suscripcion
          </StyledNavLink>
        </StyledLi>
        <StyledLi>
          <StyledNavLink to="/InstructivoOwner" activeclassname="active">
            <img src={instructivo} alt="Instructivo" />
            Instructivo
          </StyledNavLink>
        </StyledLi>
      </StyledUl>
    </StyledNav>
  );
};
export default NavigationTab;
