import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { fondo } from '../../../components/UI/variables'
import { useContext } from 'react'
import { AuthContextOwner } from "../../../context/AuthOwner";

import Boton from '../../../components/Boton'
import Titulo2 from '../../../components/Titulo2'
import ContadorHabitaciones from '../../../components/ContadorHabitaciones'
import ListaHabitacionesAdministradasRegistro from '../../../components/ListaHabitacionesAdministradasRegistro';
import mascota from "../../../assets/imagenes/mascota.png"
import { colorPrimario} from '../../../components/UI/variables'
import BoxContacto from '../../../components/BoxContacto';

const StyledSection = styled.section`
  background-color: ${fondo};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  height: 100%;
`
const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  height: 40px;
  width: 96vw;
  align-items: center;
`
const TituloStyled= styled(Titulo2)`
  width: 100%;
  text-align: center;
`
const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const DivDesplegable = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  width: 360px;
`
const StyledSelect = styled.select`
  margin: 8px 0 8px 0;
  height: 36px;
  text-align: center;
`
const StyledSectionDefault = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  height: 100%;
`
const StyledDivDefault=styled.div`
  display: flex;
  align-items: center;
  margin: 100px 8px 0px 8px;
 
  div{
    display: flex;
    flex-direction: column;
  }
  p{
    display: flex;
    flex-direction:column;
    justify-content: center;
    font-size: 18px;
    border: solid 2px ${colorPrimario};
    border-radius: 16px;
    padding: 8px;
    margin: 8px 0px 0px 0px;
  }
  img{
    width: 140px;
    height: 200px;
  }
`

const RegistrarInquilinos = () => {
  const[totalHabitaciones, setTotalHabitaciones]= useState("")
  
  const { currentUser } = useContext(AuthContextOwner);
  const suscripcionActiva=currentUser[0].suscripcionActiva;
  const propiedadesOwner=currentUser[1]
  const [propiedadSeleccionada, setPropiedadSeleccionada] = useState("")
  
  const manejarSeleccion=(e)=>{
    setDireccionSeleccionada(e.target.value)
  }
  const [direccionSeleccionada, setDireccionSeleccionada] = useState(propiedadesOwner[0]?.direccion || "")

  useEffect(()=>{
    propiedadesOwner.map((propiedadOwner)=>{
      if(propiedadOwner.direccion==direccionSeleccionada){
        setPropiedadSeleccionada(propiedadOwner)
      }
    })
  },[direccionSeleccionada, propiedadesOwner])

  const contarHabitaciones=(totalHabitaciones) =>{
    setTotalHabitaciones(totalHabitaciones)
  }

  return (
    suscripcionActiva
    ?
    <section>
      {propiedadesOwner.length<1
      ? 
      <StyledSectionDefault>
        <StyledHeader>
          <Boton to="/HomeOwnerLogin">Inicio</Boton>
          <TituloStyled $naranja>Inquilinos</TituloStyled>
        </StyledHeader>
        <StyledDivDefault>
          <div>
            <p>Antes de registrar a tus inquilinos: Primero debes agregar una propiedad</p>
            <p>Regresa a inicio y entra a la seccion "Registra tus propiedades"</p>
          </div>
          <img src={mascota} alt='Mascota de Arkilado - Perro Cuartelero'/>
        </StyledDivDefault>
      </StyledSectionDefault>
      :
        <StyledSection>
          <StyledHeader>
            <Boton to="/HomeOwnerLogin">Inicio</Boton>
            <TituloStyled $naranja>Registro de Inquilinos</TituloStyled>
          </StyledHeader>
          <StyledDiv>
            <DivDesplegable>
              <label>1. Para iniciar <strong>Selecciona tu propiedad</strong></label>
              <StyledSelect value={direccionSeleccionada} onChange={manejarSeleccion}>
                {propiedadesOwner.map((propiedadOwner, index) => (
                  <option value={propiedadOwner.direccion} key={index} >
                    {propiedadOwner.direccion}
                  </option>
                ))}
              </StyledSelect>
            </DivDesplegable>
            <ContadorHabitaciones totalHabitaciones={totalHabitaciones} imagen={propiedadSeleccionada.imagen}/>
            <ListaHabitacionesAdministradasRegistro contarHabitaciones={contarHabitaciones} propiedadSeleccionada={propiedadSeleccionada}/>
          </StyledDiv>
        </StyledSection>
     }
    </section>
    :
    <StyledSectionDefault>
      <StyledHeader>
          <Boton to="/HomeOwnerLogin">Inicio</Boton>
          <TituloStyled $naranja>Habitaciones</TituloStyled>
      </StyledHeader>
      <h1>Hola, se acabaron tus dias de suscripcion!!</h1>
      <p>Nos encantaria tenerte de vuelta, por favor contactanos para renovar tu suscripcion</p>
      <BoxContacto/>

    </StyledSectionDefault>
  )
}

export default RegistrarInquilinos