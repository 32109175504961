import React from "react";
import styled from 'styled-components';
import { useAuth } from "./AuthContext";
import { AuthProviderAdmin } from "./AuthAdmin";
import { AuthProviderOwner } from "./AuthOwner";
import { AuthProviderStudent } from "./AuthStudent";
import iconLoading from "../assets/iconos/loading.svg"

const StyledDiv=styled.div`
  height: 740px;
  display: flex;
  justify-content: center;
  align-items: center;
`
const StyledImg=styled.img`
  width: 40px;
  height: 40px;
`
const StyledP=styled.p`
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  margin-left: 16px;
`

const RoleBasedProvider = ({ children }) => {
  const { userData, usuarioActual, loading } = useAuth();

  if (loading) {
    return (
      <StyledDiv>
        <StyledImg src={iconLoading} alt="Cargando"/>
        <StyledP>Loading...</StyledP>
      </StyledDiv>
    )
  }

  if (!usuarioActual){
    return <>{children}</>
  }

  if (userData.rol === "admin") {
    return <AuthProviderAdmin>{children}</AuthProviderAdmin>;
  } else if (userData.rol === "propietario") {
    return <AuthProviderOwner>{children}</AuthProviderOwner>;
  } else if (userData.rol === "estudiante") {
    return <AuthProviderStudent>{children}</AuthProviderStudent>;
  } 

  return null; // o un fallback, como un loader o un mensaje de error
};

export default RoleBasedProvider;