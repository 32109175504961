import styled from "styled-components";
import { textoOscuro } from "./UI/variables";
import { useState, useEffect } from "react";

const StyledSection = styled.section`
  width: 328px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 8px 0px 8px 0px;
`;

const StyledInput = styled.input`
  display: flex;
  width: 74px;
  height: 12px;
  border-radius: 5px;
  justify-content: center;
  text-align: center;
  padding: 10px;
  border-width: 1px;
  border-color: ${textoOscuro};
`;

const StyledLabel = styled.label`
  width: 120px;
  height: 16px;
  font-weight: 500;
`;

const InputHorizontal = (props) => {
  const [valorEscrito, setValorEscrito] = useState("");

  const capturarValor = (e) => {
    let valor = e.target.value;
    setValorEscrito(valor);
    props.obtenerDatos(valor);
  };

  // este useEffect solo funciona cuando se recibe mediante props el valor de "datosContext", y esto solo ocurre cuando editamos una habitacion.
  useEffect(() => {
    if (props.datosContext) {
      setValorEscrito(props.datosContext);
    }
  }, [props.datosContext]);

  return (
    <StyledSection>
      <StyledLabel> {props.$etiqueta} </StyledLabel>
      <StyledInput
        type="number"
        placeholder="0"
        value={valorEscrito}
        onChange={capturarValor}
      />
    </StyledSection>
  );
};

export default InputHorizontal;
