import React from 'react'
import styled from 'styled-components'

const StyledDiv = styled.div`
    padding: 16px;
    text-align: justify;
`

const RedaccionTyc = () => {
  return (
    <StyledDiv>
        <h2>A. TÉRMINOS Y CONDICIONES</h2>
        <p>Arkilados proporciona servicios en línea tanto a usuarios (que buscan alojamiento) como a proveedores de alojamiento (que desean ofrecer su alojamiento a los usuarios y administrar sus alquileres). No alquila alojamiento directamente a los usuarios.</p>
        <p>Los acuerdos de las condiciones de alquiler entre un propietario y un estudiante son definidos por el propietario. Arkilados no se involucra y no será responsable de dichas condiciones.</p>

        <h3>1. Sobre estos Términos de Servicio</h3>
        <p>«Alojamiento» se refiere a una habitación o un departamento que ofrece un proveedor a través de la página web; «Proveedor de alojamiento» se refiere al proveedor de alojamiento que aparece en la lista de la página web; «Inquilino», se refiere a aquel que contrata un alojamiento; «Usuario» se refiere a aquel que utiliza la página web para buscar alojamiento; «Fecha de entrada» se refiere a la fecha de ingreso al alojamiento como inquilino, tal y como se especifica cuando un inquilino es registrado por el proveedor de Alojamiento en una habitación; «Fecha de Inicio», se refiere a la fecha de inicio de un periodo de alquiler; «Precio de Alquiler», se refiere al importe correspondiente al alquiler mensual (30 días) del alojamiento determinado por el proveedor de alojamiento; «Servicios incluidos», se refiere a todos los servicios que se ofrecen con el alojamiento como: SSHH. privado, internet, TV cable, etc.; «Equipamiento incluido», se refiere a todos muebles que se ofrecen con el alojamiento como: camas, sillas, mesas, etc.; «Servicios» se refiere a la prestación de nuestras herramientas de búsqueda y administración de alojamiento por medio de la página web; «Contrato de alquiler» se refiere a cualquier acuerdo, formal o informal, escrito o verbal, entre el proveedor de alojamiento y el inquilino, que regula el uso o alquiler de un alojamiento; «Periodo de alquiler» se refiere al plazo de duración de un mes (30 días) contabilizados desde la fecha de inicio o fecha de entrada; «Página web», se refiere a la página de Arkilados; «Tú» se refiere al usuario o proveedor de alojamiento (según el caso); «Estadía» se refiere al tiempo que el inquilino lleva alquilado en un alojamiento, esta puede estar compuesta por varios periodos de alquiler.</p>
        <p>Estos términos de servicio establecen los términos en los cuales ofrecemos los servicios y la página web y según los cuales aceptas utilizar los servicios proporcionados por nosotros y la página web gestionada por nosotros. Por favor, lee estos términos de servicio detenidamente y asegúrate de comprenderlos antes de utilizar los servicios o la página web. Si no aceptas estos términos de servicio debes dejar de utilizar los servicios y la página web de forma inmediata.</p>

        <h3>2. Aceptación de los Términos de Servicio</h3>
        <p>2.1 Al registrarte para utilizar los servicios y/o la página web y/o al pedir a Arkilados que cree listas de alojamiento para ti aceptas estar vinculado por estos términos de servicio.</p>
        <p>2.2 En cualquier momento podemos modificar estos términos de servicio. Te notificaremos de cualquier cambio en estos términos de servicio por email (a la dirección que hayas proporcionado al momento de crear tu cuenta en la plataforma) antes de que los cambios se hagan efectivos para darte la oportunidad de cancelar el contrato si no estás de acuerdo con ellos. Si continúas utilizando los servicios y/o la página web después de la fecha de caducidad enviada en el mensaje, aceptas estar vinculado por dichos cambios. Puedes revisar la versión más reciente de nuestros términos de servicio en cualquier momento haciendo clic en el enlace Ver Términos y Políticas.</p>

        <h3>3. ¿Qué es la página web?</h3>
        <p>3.1 La página web es una plataforma electrónica que permite a los usuarios ojear, buscar y contactar alojamiento y a los proveedores de alojamiento anunciar alojamiento a los usuarios y administrar sus alquileres.</p>
        <p>3.2 Arkilados ofrece servicios de publicidad y administración a proveedores de alojamiento, pero no ofrece alojamiento a usuarios en nombre de los proveedores. Los contratos de alquiler se realizan entre usuario y proveedor de alojamiento. Arkilados no será una de las partes en tales contratos de alquiler y no tendrá ningún derecho u obligación que surja del mismo.</p>

        <h3>4. Información Mostrada en la Página Web</h3>
        <p>4.1 Arkilados depende de los proveedores de alojamiento para ofrecer información apropiada y precisa para mantener actualizada dicha información. No verificamos la veracidad ni el estado de actualización de la información publicada o proporcionada por los proveedores, a menos que confirmemos de forma expresa haberlo hecho. Si eres un proveedor de alojamiento reconoces y aceptas que eres el único responsable del contenido de la información publicada o enviada a nosotros relativa a cualquier alojamiento anunciado en la página web.</p>
        <p>4.2 Si eres proveedor de alojamiento debes comprobar la información correspondiente de todos los alojamientos que ofrezcas en nuestra página, incluyendo (entre otra información) el precio de alquiler, los servicios y el equipamiento incluidos a través de Arkilados.</p>
        <p>4.3 Como usuario, reconoces y aceptas que Arkilados no tiene ninguna responsabilidad en relación a la información publicada en la página web o que nos haya sido compartida por los propietarios en relación al Alojamiento. Asimismo, también reconoces que cualquier pérdida o daño que puedas sufrir y que resulte de la información escrita en relación al Alojamiento es responsabilidad del propietario.</p>

        <h3>5. Servicio de administración de alquileres</h3>
        <p>5.1 Con respecto a la adquisición del servicio, si eres proveedor de alojamientos y deseas administrar tus alquileres, deberás contactarte con Arkilados mediante Nro de teléfono, correo electrónico, o redes sociales para adquirir un plan de suscripción de acuerdo al número de habitaciones. Asimismo si el proveedor de alojamiento ya cuenta con una suscripción y no la renueva antes de que finalice, dejará de contar con los servicios de Arkilados hasta que renueve su  suscripción.</p>
        <p>5.2 El servicio de administración de alquileres permite al proveedor de alojamiento registrar sus habitaciones en alquiler(precios de alquiler,ubicación, servicios y equipamiento incluidos  entre otros), ver la disponibilidad de las habitaciones, registrar inquilinos, registrar pagos del inquilinos, visualizar el tiempo de estadía y deuda de los mismos. Además incluye la publicación de sus alquileres en la página web.</p>
        <p>5.3 Para hacer uso del servicio el proveedor de alojamiento debe contar con una suscripción activa, la cual varía según el número de habitaciones administradas. Además debe proporcionar información actualizada y precisa sobre los alojamientos para garantizar que los usuarios reciban datos confiables.</p>
        <p>5.4 Arkilados proporcionará actualizaciones periódicas para mejorar la página web. Estás actualizaciones podrán incluir nuevas funciones, mejoras de rendimiento y correcciones de errores.</p>
        <p>5.5 El proveedor de alojamiento puede cancelar la suscripción en cualquier momento, pero no se reembolsaran pagos ya efectuados.</p>

        <h3>6. Alquilar Alojamiento</h3>
        <p>6.1 El usuario tendrá acceso al número de celular proporcionado por el proveedor de alojamiento dando click en el botón contactar de la página web, para acordar una reunión y/o llegar a un acuerdo respecto a su estadía.</p>
        <p>6.2 El Usuario garantiza que es mayor de edad para formalizar un contrato de alquiler en el lugar donde se ubica el alojamiento, en caso contrario deberá tener un apoderado que se encuentre facultado para formalizar un contrato de alquiler. Es responsabilidad del inquilino comprobar que puede formalizar un contrato de alquiler de forma legal con el proveedor de alojamiento.</p>
        <p>6.3 El precio del alquiler será el mostrado en la página web el cual es determinado por el proveedor de alojamiento. La concurrencia de los pagos y el método de pago serán acordados con el proveedor de alojamiento.</p>
        <p>6.4 La estancia mínima será determinada por el propietario. Sin embargo la estancia mínima permitida por la plataforma tendrá una duración de 30 días, lo cual se considera un mes de alquiler.</p>
        <p>6.5 El alquiler inicia desde la fecha acordada con el proveedor de alojamiento que será registrada en su sistema junto con los datos del inquilino en el alojamiento escogido y pactado.</p>
        <p>6.6 Se concluirá un alquiler entre el proveedor de alojamiento y el inquilino. de acuerdo a los términos establecidos por el proveedor y/o después de haber terminado la estadía pactada.</p>

        <h3>7. Fecha de Entrada</h3>
        <p>El proveedor de alojamiento acordará con el usuario la fecha de entrada la cual se contabilizará como el primer día de alquiler. Todos los pagos se realizarán directamente al proveedor de alojamiento. Para cualquier aclaración el usuario deberá contactar con el proveedor de alojamiento.</p>

        <h3>8. Tu Conducta</h3>
        <p>8.1 Al utilizar la página web aceptas no subir, publicar, enviar por email o transmitir de otro modo cualquier material que contenga virus, troyanos, gusanos informáticos o cualquier otro código de ordenador malintencionado, archivos o programas diseñados para interrumpir, destruir o limitar la funcionalidad de cualquier software o hardware de ordenador.</p>
        <p>8.2 Aceptas y te comprometes a mantener confidenciales tu nombre de usuario y contraseña, a no difundir tu contraseña a otras personas y a no permitir que otras personas inicien sesión en la página web utilizando tu nombre de usuario y contraseña.</p>
        <p>8.3 Aceptas no interferir con los servidores o las redes conectadas a la página web o violar alguno de los procedimientos, las políticas o los reglamentos de las redes conectadas a la página web, incluyendo estos términos de servicio. Asimismo, aceptas:</p>
        <ul>
            <li>(a) no intentar realizar ingeniería inversa o vender, exportar, autorizar, modificar, copiar, distribuir o transferir la página web a terceros, o poner en peligro el correcto funcionamiento de la página web o de otro modo intentar extraer el código fuente del software (incluyendo las herramientas, los métodos, los procesos y la infraestructura) que permite o es la base de la página web;</li>
            <li>(b) no intentar obtener acceso a secciones seguras de la página web para las que no tienes derechos de acceso;</li>
            <li>(c) no intentar hacerse pasar por otra persona mientras utilizas los servicios o la página web;</li>
            <li>(d) no mostrar un comportamiento vulgar, ofensivo, intimidatorio o inaceptable mientras utilizas la página web;</li>
            <li>(e) no revender o exportar el software asociado a la página web;</li>
            <li>(f) no utilizar la página web para generar anuncios no deseados o mensajes basura;</li>
            <li>(g) no utilizar procesos automáticos o manuales para buscar y recopilar información de la página web o interferir de algún modo con el correcto funcionamiento de la página web.</li>
        </ul>
        <h3>9. Derechos de Arkilados</h3>
        <p>9.1 Al acceder, navegar o de otro modo utilizar la página web de Arkilados, confieres a Arkilados (y aceptas que Arkilados tenga) los siguientes derechos:</p>
        <ul>
            <li>(a) El derecho de suspender, modificar, deshabilitar o denegar el acceso a tu cuenta en cualquier momento (sin previo aviso) según las leyes aplicables si violas o infringes cualquiera de estos términos de servicio.</li>
            <li>(b) El derecho de modificar o actualizar la página web, las tarifas, los métodos de facturación o estos términos de servicio.</li>
            <li>(c) El derecho de informar a la policía u otras autoridades judiciales si considera, a su discreción, que tu conducta es ilegal, ya sea al utilizar la página web, nuestros servicios o en cualquier otra situación.</li>
        </ul>

        <h3>10. Propiedad Intelectual</h3>
        <p>10.1 Arkilados son los únicos dueños de la página web, que incluye cualquier software, dominios y contenido disponible a través de la página web. La página web está protegida por derechos de autor del Perú y otras leyes de propiedad intelectual.</p>
        <p>10.2 Aceptas que no puedes vender, exportar, autorizar, modificar, copiar, distribuir, transferir y entre otras cosas la página web (o parte de ella) o cualquier material proporcionado a través de la web sin el consentimiento expreso previo y por escrito de Arkilados.</p>
        <p>10.3 Arkilados te permite usar sus recursos de la página web (como fotos, descripciones y herramientas de búsqueda, etc.) mientras estés usando la plataforma para buscar y ofrecer alojamientos. No puedes transferir este permiso a otra persona; solo tú, como usuario registrado, puedes usarlo. Arkilados puede quitarte este permiso en cualquier momento si se decide que no cumples con sus términos y condiciones. Tu uso de estos recursos está limitado solo a lo necesario para que puedas buscar, listar y ofrecer alojamientos. No tienes permiso para usar estos recursos para otros fines, como copiarlos para tu propio sitio web.</p>
        <p>10.4 Arkilados y sus gráficos, logotipos, iconos y los nombres del servicio relacionado con la página web son marcas registradas, no registradas o etiquetas comerciales de Arkilados. No pueden utilizarse sin el consentimiento expreso previo y por escrito de Arkilados.</p>
        <p>10.5 Aceptas que Arkilados es dueño de los derechos de autor de cualquier contenido, ya sea textual, gráfico, fotográfico o de vídeo que cree y publique en su página web y no debes vender, exportar, autorizar, modificar, copiar, distribuir o transferir este contenido a terceros.</p>
        <p>10.6 Otorgas a Arkilados una licencia global libre de regalías, no exclusiva, intransferible y revocable para utilizar tus derechos de propiedad intelectual solamente hasta el punto necesario que permita a Arkilados ofrecerte servicios. Para mayor claridad, dicho uso puede incluir cosas como mostrar tu nombre junto a una lista de alojamientos.</p>
        <p>10.7 Deberás reembolsar a Arkilados bajo petición cualquier gasto o indemnización por daños y perjuicios que surja como resultado de cualquier presunta (o real) infracción de la propiedad intelectual de terceros (o de otros derechos) causada por la información que tú has proporcionado a su página web.</p>

        <h3>11. Seguridad</h3>
        <p>Pese a que hemos puesto en marcha medidas técnicas y de organización razonables a nivel comercial para mantener segura tu información personal y el contenido de usuario ante usos no autorizados, no podemos garantizar que terceros no autorizados nunca superen dichas medidas. Aceptas proporcionar tu información personal y contenido de usuario bajo tu responsabilidad.</p>

        <h3>12. Comunicaciones Electrónicas</h3>
        <p>Al utilizar la página web y/o el material proporcionado a través de la página web, aceptas recibir comunicaciones y avisos electrónicos de Arkilados. Aceptas que cualquier aviso, acuerdo, divulgación u otra comunicación que te enviemos de forma electrónica son legalmente válidos.</p>

        <h3>13. Privacidad</h3>
        <p>Aceptas proporcionarnos tu información cuando te registras en la página web. También recopilamos información tuya (por ejemplo sobre el historial de uso y tus preferencias relativas a ciertos tipos de ofertas) y de otros usuarios de la página web y de los servicios en general.</p>

        <h2>B. POLÍTICA DE PRIVACIDAD</h2>
        <h3>1. Introducción</h3>
        <p>Esta página está diseñada para ayudarte a entender por qué y cómo se utilizan los datos personales. Por datos personales se entiende la información referente a un individuo vivo que puede identificarse con dicha información.</p>
        <p>Arkilados ofrece un servicio en línea que permite a los proveedores de alojamiento anunciar alojamiento a los usuarios y administrar sus alquileres, y a los usuarios ojear, buscar y contactar alojamiento, lo que les permite navegar a través de varias propiedades. Para ello, tenemos relaciones con los inquilinos, proveedores de alojamiento y nuestros proveedores. Procesamos los datos personales de manera diferente para cada una de esas categorías de personas, y lo hacemos para diferentes propósitos.</p>

        <h3>2. ¿Qué datos personales se utilizan?</h3>
        <p>Vamos a utilizar los datos proporcionados por usted al crear su cuenta y/o al agregar una lista de propiedades y habitaciones, incluyendo: nombre, ubicación, número de teléfono, dirección, detalles de la propiedad y habitación, precio de alquiler, servicios incluidos, equipamientos incluidos, reglas de la casa, el género, la edad, si usted es un propietario residente, las preferencias de los inquilinos, la disponibilidad de bienes y cualquier imágen o texto que usted pueda proporcionar libremente.</p>

        <h3>3. ¿Cómo Arkilados utiliza los datos personales para la comercialización?</h3>
        <p>En resumen, independientemente de si usted es un propietario o inquilino, utilizamos los datos personales para:</p>
        <ul>
            <li>La comercialización de nuestro servicio a los inquilinos y propietarios a través de correo electrónico, incluyendo el envío de nuestros boletines.</li>
            <li>Adaptar la comercialización a posibles intereses del destinatario.</li>
            <li>Comercializar nuestros servicios a inquilinos y propietarios a través del teléfono.</li>
            <li>Generar publicidad gráfica en las plataformas de Facebook, Instagram, Google Ads, etc.</li>
            <li>Orientar anuncios de visualización en otros sitios web a personas en particular.</li>
            <li>Crear audiencias en las redes de publicidad que utilizamos.</li>
        </ul>

        <h3>4. ¿Cómo Arkilados utiliza de otra manera los datos personales en el funcionamiento de su negocio?</h3>
        <p>Independientemente de si usted es un propietario o inquilino, utilizamos los datos personales para:</p>
        <ul>
            <li>Permitir que los inquilinos y propietarios inicien sesión en el sitio web de Arkilados.</li>
            <li>Informar al inquilino el número de contacto del propietario</li>
            <li>Almacenar los datos del inquilino en el registro de entrada.</li>
            <li>Obtener retroalimentación de los inquilinos y propietarios sobre el servicio de Arkilados.</li>
            <li>Permitir que los inquilinos y propietarios completen encuestas con fines de investigación de mercado.</li>
            <li>Mantener registros financieros.</li>
            <li>Capacitar a nuestro personal.</li>
            <li>Atender consultas y quejas.</li>
            <li>Presentar o defender demandas legales.</li>
        </ul>

        <h3>5. ¿Con quién Arkilados comparte datos personales?</h3>
        <p>A nivel interno, Arkilados podrá conceder el acceso a los datos personales para fines de formación del personal para que puedan llevar a cabo su función. Externamente, Arkilados puede compartir los datos personales con terceros para fines específicos, según lo establecido en los apartados anteriores. También, de vez en cuando, compartiremos los datos personales con las siguientes categorías de destinatarios:</p>
        <p>Proveedores de servicios, por ejemplo:</p>
        <ul>
            <li>Empresas que alojan y administran nuestra infraestructura de TI.</li>
            <li>Empresas que nos proporcionan sistemas de TI basados en la nube o sistemas de entrega de correo electrónico.</li>
            <li>Asesores externos, como consultores de TI, contadores y abogados.</li>
            <li>Reguladores, la policía, servicios de inteligencia y otras autoridades gubernamentales, que nos obligan a hacerlo.</li>
        </ul>
        
        <h3>6. ¿Cómo y cuándo Arkilados hace cambios a estas políticas?</h3>
        <p>Podemos actualizar estas políticas de privacidad al actualizar la página web. Se le notificará que los cambios han sido realizados a través de la dirección de correo electrónico que nos proporcionaste en el momento de tu registro, si los hubiere.</p>

    </StyledDiv>
  )
}

export default RedaccionTyc