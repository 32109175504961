import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContextOwner } from "../context/AuthOwner";

const PrivateRouteOwner = () => {
  const { currentUser } = useContext(AuthContextOwner);

  return !!currentUser ? <Outlet /> : <Navigate to="/SignIn" />;
};

export default PrivateRouteOwner;
