import React, { useState, useCallback } from "react";
import styled from "styled-components";
import Entrada from "../Entrada";
import appFirebase from "../../firebase";
import {getFirestore, doc, setDoc} from "firebase/firestore";
import BotonSubmit from "../BotonSubmit";
import {getAuth, signOut, createUserWithEmailAndPassword, sendEmailVerification} from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const StyledDiv = styled.div`
  display: flex;
  width: 328px;
  margin-top: 50px;
  margin-bottom: 8px;
`;
const StyledP=styled.p`
  margin: -8px 0px 8px 0px;
  font-size: 12px;
`;
const PInstruccion = styled.p`
  margin: 0px 0px 8px 0px;
  font-size: 12px;
  width: 328px;
  text-align: left;
`
const PTyC = styled.p`
  display: block  ;
  color: blue;
  font-size: 12px;
  margin-bottom: 64px;
  text-decoration: underline;
`
const auth = getAuth(appFirebase);
const db = getFirestore(appFirebase);

const FormStudent = () => {
  const navigate = useNavigate();
  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [correo, setCorreo] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [carrera, setCarrera] = useState("");
  const [aceptacionTyC, setAceptacionTyC] = useState(false);
  const [procesoEnvioIniciado, setProcesoEnvioIniciado] = useState(false);
  const [requisito, setRequisito] = useState(false);

  const mostrarRequisito = () => {
    setRequisito(true);
  };

  const manejarNombre = (e) => {
    setNombre(e.target.value);
  };
  const manejarApellido = (e) => {
    setApellido(e.target.value);
  };
  const manejarCorreo = (e) => {
    setCorreo(e.target.value);
  };
  const manejarPassword = (e) => {
    setPassword(e.target.value);
  };
  const manejarConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
    setPasswordMatch(e.target.value === password);
  };
  const manejarCarrera = (e) => {
    setCarrera(e.target.value);
  };
  const manejarAceptacionTyC = (e) => {
    setAceptacionTyC(e.target.checked);
  };
  const confirmarInicioDeEnvio = () => {
    setProcesoEnvioIniciado(true);
  };
  const hoy = new Date();

  const abrirTyC = () => {
    window.open('/TyC', '_blank');
  };

  //xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
  const manejarEnvio = useCallback(
    async (e) => {
      e.preventDefault();
      confirmarInicioDeEnvio()
      if(passwordMatch==true){
        const newRegistro = {
          nombre: nombre,
          correo: correo,
          apellido: apellido,
          rol: "estudiante",
          uuid: uuidv4(),
          carrera: carrera,
          bloqueado: false,
          aceptacionTyC: aceptacionTyC,
          fechaAceptacionTyC: hoy
        };
  
        try {
          const infoUsuario = await createUserWithEmailAndPassword(
            auth,
            correo,
            password
          ).then((usuarioFirebase) => {
            return usuarioFirebase;
          });
          const idUsuario = infoUsuario.user.uid;
  
          const docuRef = doc(db, `usuarios/${idUsuario}`);
          await setDoc(docuRef, { ...newRegistro });

          const configuracion = {
            url: process.env.NODE_ENV === "production"
              ? "https://www.arkilados.com/" // URL en producción
              : "http://localhost:3000"      // URL en desarrollo
          };
          await sendEmailVerification(auth.currentUser, configuracion);
  
          await signOut(auth);
          navigate("/SignIn");
          alert("Para ingresar a tu cuenta necesitas verificar tu correo electronico, ingresa a tu correo, haz click en el link de verificacion y listo, no olvides revisar la carpeta de Spam o No deseados")
        } catch (error) {
          alert("Se ha producido un error en tu registro, revisa tus datos y vuelve a intentarlo.")
        }
      }
    },
    [navigate, correo, password, nombre, apellido, carrera, aceptacionTyC, passwordMatch]
  );

  return (
    <form onSubmit={manejarEnvio}>
      <Entrada value={nombre} onChange={manejarNombre} placeholder="Nombres" required />
      <Entrada value={apellido} onChange={manejarApellido} placeholder="Apellidos" required />
      <Entrada type="email" value={correo} onChange={manejarCorreo} placeholder="Correo" required />
      <Entrada type="password" value={password} onChange={manejarPassword} onFocus={mostrarRequisito} placeholder="Contraseña" required pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[a-zA-Z\d\W_]{6,}$" title="Al menos 6 caracteres, debe contener una letra minuscula, una letra mayuscula, un numero, y un caracter especial."/>
      {requisito && (
          <PInstruccion>
            Debe tener al menos 6 caracteres, 1 mayúscula, 1 minúscula, un número y un símbolo (ej: !@#$%)
          </PInstruccion>
        )}
      <Entrada type="password" value={confirmPassword} onChange={manejarConfirmPassword}  placeholder="Confirmar contraseña" required pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_])[a-zA-Z\d\W_]{6,}$" title="La contraseña no coincide"/>
      {!passwordMatch && <StyledP style={{color: "red"}}>Las contraseñas no coinciden. Intentalo de nuevo.</StyledP>}
      <Entrada type="text" value={carrera} onChange={manejarCarrera} placeholder="Carrera" required />
      <StyledDiv>
        <input value={aceptacionTyC} onChange={manejarAceptacionTyC}id="vinculo" type="checkbox" required title="Debes aceptar los terminos y condiciones para continuar"/>
        <label htmlFor="vinculo">
          Acepto los terminos de servicio y politicas de privacidad
        </label>
      </StyledDiv>
      <PTyC onClick={abrirTyC}>Ver Terminos y Politicas</PTyC>
      <BotonSubmit $large $procesoenvioiniciado={procesoEnvioIniciado} disabled={procesoEnvioIniciado ? true : false}>{procesoEnvioIniciado ? "Registrandote... ⌛⌛" : "Registrate"}</BotonSubmit>
    </form>
  );
};
export default FormStudent;
