import React, { useEffect, useState } from "react";
import styled from "styled-components";
import iconoDuchaAguaCaliente from "../../assets/iconos/duchaAguaCaliente.svg";
import iconoDuchaAguaFria from "../../assets/iconos/duchaAguaFria.svg";
import iconoBañoPrivado from "../../assets/iconos/banoPrivado.svg";
import iconoAccesoTendedero from "../../assets/iconos/accesoTendedero.svg";
import iconoBañoCompartido from "../../assets/iconos/banoCompartido.svg";
import iconoInternet from "../../assets/iconos/internet.svg";
import iconoTvCable from "../../assets/iconos/tvCable.svg";
import iconoVentilador from "../../assets/iconos/ventilador.svg";
import iconoCalefaccion from "../../assets/iconos/calefaccion.svg";

const StledDiv = styled.div`
  margin: 16px 0 0 0;
  width: 328px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
const StyledLabel = styled.label`
  display: flex;
  align-items: center;
  width: 160px;
  height: 40px;
  justify-content: space-between;
  margin: 0 0 8px 0;
  text-align: center;
`;
const StyledInput = styled.input`
  margin: 0;
`;

const CheckboxServicios = (props) => {
  const servicios = [
    {
      nombre: "Baño privado",
      icono: iconoBañoPrivado,
      nombreFirebase: "banhoPrivado",
    },
    {
      nombre: "Baño compartido",
      icono: iconoBañoCompartido,
      nombreFirebase: "banhoCompartido",
    },
    {
      nombre: "Ducha agua fria",
      icono: iconoDuchaAguaFria,
      nombreFirebase: "duchaFria",
    },
    {
      nombre: "Ducha agua caliente",
      icono: iconoDuchaAguaCaliente,
      nombreFirebase: "duchaCaliente",
    },
    {
      nombre: "Acceso a tendedero",
      icono: iconoAccesoTendedero,
      nombreFirebase: "accesoTendedero",
    },
    {
      nombre: "Internet",
      icono: iconoInternet,
      nombreFirebase: "internet",
    },
    {
      nombre: "TV cable",
      icono: iconoTvCable,
      nombreFirebase: "tvCable",
    },
    {
      nombre: "Ventilador",
      icono: iconoVentilador,
      nombreFirebase: "ventilador",
    },
    {
      nombre: "Calefaccion",
      icono: iconoCalefaccion,
      nombreFirebase: "calefaccion",
    },
  ];

  const initialState = servicios.reduce((acc, servicio) => {
    return { ...acc, [servicio.nombreFirebase]: false };
  }, {});

  const [checkboxValues, setCheckboxValues] = useState(initialState);
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setCheckboxValues({
      ...checkboxValues,
      [name]: checked,
    });
  };

  useEffect(() => {
    props.obtenerDatos(checkboxValues);
  }, [checkboxValues]);

  // este useEffect solo funciona cuando se recibe mediante props el valor de "checkboxContext", y esto solo ocurre cuando editamos una habitacion
  useEffect(() => {
    if (props.checkboxContext) {
      setCheckboxValues(props.checkboxContext);
    }
  }, [props.checkboxContext]);

  return (
    <StledDiv>
      {servicios.map((servicio, indice) => {
        return (
          <StyledLabel key={indice}>
            <img src={servicio.icono} alt={servicio.nombreFirebase} />
            {servicio.nombre}
            <StyledInput
              type="checkbox"
              name={servicio.nombreFirebase}
              checked={!!checkboxValues[servicio.nombreFirebase]}
              onChange={handleCheckboxChange}
            />
          </StyledLabel>
        );
      })}
    </StledDiv>
  );
};

export default CheckboxServicios;
