import React, { createContext, useState, useEffect, useContext } from "react";
import appFirebase from "../firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, doc, onSnapshot} from "firebase/firestore";

const auth = getAuth(appFirebase);
const db = getFirestore(appFirebase);


const AuthContext = createContext();

export const AuthProviderContext = ({ children }) => {
  const [usuarioActual, setUsuarioActual] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState({});

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (usuarioFirebase) => {
        setUsuarioActual(usuarioFirebase);
      if (usuarioFirebase) {
        const getInfoUsuario = (uid) => {
            const docuRef = doc(db, `usuarios/${uid}`);
            onSnapshot(docuRef, (docSnapshot) => {
              setUserData(docSnapshot.data())
            });
        };
        getInfoUsuario(usuarioFirebase.uid);
      } else {
        setUserData({});
      }
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  return (
    <AuthContext.Provider value={{ usuarioActual, userData, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};