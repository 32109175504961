import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { fondo } from '../../../components/UI/variables'
import { Link } from 'react-router-dom'
import { useContext } from 'react'
import { AuthContextOwner } from "../../../context/AuthOwner";

import Boton from '../../../components/Boton'
import Titulo2 from '../../../components/Titulo2'
import ContadorHabitaciones from '../../../components/ContadorHabitaciones'
import AddTarjetaHabitacion from '../../../components/AddTarjetaHabitacion'
import ListaHabitacionesRegistradasOwner from '../../../components/ListaHabitacionesRegistradasOwner'
import mascota from "../../../assets/imagenes/mascota.png"
import { colorPrimario} from '../../../components/UI/variables'

const StyledSection = styled.section`
  background-color: ${fondo};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  height: 100%;
`
const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  height: 40px;
  width: 96vw;
  align-items: center;
`
const TituloStyled= styled(Titulo2)`
  width: 100%;
  text-align: center;
`
const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const DivDesplegable = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  width: 360px;
`
const StyledSelect = styled.select`
  margin: 8px 0 8px 0;
  height: 36px;
  text-align: center;
`
const StyledSectionDefault = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  height: 100%;
`
const StyledDivDefault=styled.div`
  display: flex;
  align-items: center;
  margin: 100px 8px 0px 8px;
 
  div{
    display: flex;
    flex-direction: column;
  }
  p{
    display: flex;
    flex-direction:column;
    justify-content: center;
    font-size: 18px;
    border: solid 2px ${colorPrimario};
    border-radius: 16px;
    padding: 8px;
    margin: 8px 0px 0px 0px;
  }
  img{
    width: 140px;
    height: 200px;
  }
`
const AgregarHabitaciones = () => {
  const[totalHabitaciones, setTotalHabitaciones]= useState("")
  const { currentUser } = useContext(AuthContextOwner);
  const propiedadesOwner=currentUser[1]
  const [propiedadSeleccionada, setPropiedadSeleccionada] = useState("")

  const manejarSeleccion=(e)=>{
    setDireccionSeleccionada(e.target.value)
  }

  //Obtenemos el objeto de la propiedad seleccionada en el desplegable con todos sus datos
  const [direccionSeleccionada, setDireccionSeleccionada] = useState(propiedadesOwner[0]?.direccion || "")
  useEffect(()=>{
    propiedadesOwner.map((propiedadOwner)=>{
      if(propiedadOwner.direccion==direccionSeleccionada){
        setPropiedadSeleccionada(propiedadOwner)
      }
    })
  },[direccionSeleccionada])

  //Definimos funcion para contarHabitaciones de una propiedad
  const contarHabitaciones=(totalHabitaciones) =>{
    setTotalHabitaciones(totalHabitaciones)
  }

  return (
    <section>
      {propiedadesOwner.length<1
        ?
        <StyledSectionDefault>
          <StyledHeader>
            <Boton to="/HomeOwnerLogin">Inicio</Boton>
            <TituloStyled $naranja>Habitaciones</TituloStyled>
          </StyledHeader>
          <StyledDivDefault>
            <div>
              <p>Antes de registrar una habitacion: Primero debes agregar una propiedad</p>
              <p>Regresa a inicio y entra a la seccion "Registra tus propiedades"</p>
            </div>
            <img src={mascota} alt='Mascota de Arkilados - perro cuartelero'/>
          </StyledDivDefault>
        </StyledSectionDefault>
        :
        <StyledSection>
          <StyledHeader>
            <Boton to="/HomeOwnerLogin">Inicio</Boton>
            <TituloStyled $naranja>Habitaciones</TituloStyled>
          </StyledHeader>
          <StyledDiv>
            <DivDesplegable>
              <label>1. Para iniciar <strong>Selecciona tu propiedad</strong></label>
              <StyledSelect value={direccionSeleccionada} onChange={manejarSeleccion}>
                {propiedadesOwner.map((propiedadOwner, index) => (
                  <option value={propiedadOwner.direccion} key={index} >
                    {propiedadOwner.direccion}
                  </option>
                ))}
              </StyledSelect>
            </DivDesplegable>
            <ContadorHabitaciones totalHabitaciones={totalHabitaciones} imagen={propiedadSeleccionada.imagen}/>
            <p >2. Para <strong>agregar una Habitacion</strong>, presione el botón "Agregar una Habitacion"</p>
            <Link to={`/RegistroHabitacion/${propiedadSeleccionada.idPropiedad}`}>
              <AddTarjetaHabitacion />
            </Link>
            <ListaHabitacionesRegistradasOwner contarHabitaciones={contarHabitaciones} propiedadSeleccionada={propiedadSeleccionada}/>
          </StyledDiv>
        </StyledSection>
      }
    </section>
  )
}
export default AgregarHabitaciones