import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

    * {
    font-size: 14px;
    color: #474747;
    font-family: "Montserrat"
  
    }
 
`;

export default GlobalStyle;
