import React, { useState, useRef, useContext, useEffect } from "react";
import Etiqueta from "../Etiqueta";
import InputHorizontal from "../InputHorizontal";
import InputHorizontalRequired from "../InputHorizontalRequired";
import AreaTexto from "../AreaTexto";
import CheckboxServicios from "../CheckboxServicios";
import Entrada from "../Entrada";
import BotonSubmit from "../BotonSubmit";
import styled from "styled-components";
import appFirebase from "../../firebase";
import { getFirestore, doc, updateDoc } from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useParams, useNavigate } from "react-router-dom";
import { AuthContextOwner } from "../../context/AuthOwner";
import { textoOscuro } from "../UI/variables";

const StyledForm = styled.form`
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  width: 328px;
  height: 1208px;
`;
const EntradaFile = styled(Entrada)`
  display: none;
`;
const StyledLabel = styled.label`
  width: 328px;
  height: 200px;
  border: 2px dashed ${textoOscuro};
  margin: 8px 0 8px 0;
`;
const StyledImg = styled.img`
  width: 328px;
  height: 200px;
  object-fit: cover;
`;

const db = getFirestore(appFirebase);
const storage = getStorage(appFirebase);

const FormEditarHabitacion = () => {
  const navigate = useNavigate();
  const { currentUser } = useContext(AuthContextOwner);
  let { idPropiedad } = useParams();
  let { idHabitacion } = useParams();
  //Funcionamiento de inputs Horizontales
  const [nroHabitacion, setNroHabitacion] = useState("");
  const [area, setArea] = useState("");
  const [sillas, setSillas] = useState("0");
  const [camas, setCamas] = useState("0");
  const [armarios, setArmarios] = useState("0");
  const [mesas, setMesas] = useState("0");
  const [veladores, setVeladores] = useState("0");

  const [descripcion, setDescripcion] = useState("");
  const [precio, setPrecio] = useState("");

  const [sourceImagen, setSourceImagen] = useState("");
  const [urlImagen, setUrlImagen] = useState("");
  const [textoBoton, setTextoBoton] = useState("Guardar cambios");

  const propiedades = currentUser[1];
  const habitaciones = currentUser[2];
  const [uuidPropiedad, setUuidPropiedad] = useState("");
  const [uuidHabitacion, setUuidHabitacion] = useState("");
  const [procesoEnvioIniciado, setProcesoEnvioIniciado] = useState(false);

  const [preciosHabitacionesFirebase, setPreciosHabitacionesFirebase] = useState([]);
  const [precioHabitacionSeleccionadaFirebase, setPrecioHabitacionSeleccionadaFirebase] = useState(null);
  const [preciosHabitacionesEnviar, setPreciosHabitacionesEnviar] = useState([]);
  

  useEffect(() => {
    propiedades.map((propiedad) => {
      if (propiedad.idPropiedad == idPropiedad) {
        setUuidPropiedad(propiedad.uuidPropiedad);
        setPreciosHabitacionesFirebase(propiedad.preciosHabitaciones);
      }
    });

    habitaciones.map((habitacion) => {
      if (habitacion.idHabitacion == idHabitacion) {
        setUuidHabitacion(habitacion.uuidHabitacion);
      }
    });
  }, []);

  const [checkboxContext, setCheckboxContext] = useState({});

  useEffect(() => {
    habitaciones.map((habitacion) => {
      if (habitacion.idHabitacion == idHabitacion) {
        setNroHabitacion(habitacion.NroHabitacion);
        setArea(habitacion.Area);
        setSillas(habitacion.Sillas);
        setCamas(habitacion.Camas);
        setArmarios(habitacion.Armarios);
        setMesas(habitacion.Mesas);
        setVeladores(habitacion.Veladores);
        setDescripcion(habitacion.Descripcion);
        setPrecio(habitacion.Precio);
        //setSourceImagen variara entre una url y un dataUrl para cambiar la imagen que se muestra en el formulario
        setSourceImagen(habitacion.Imagen);
        // setUrlImagen siempre guardara la url de la imagen que se trajo desde contexto
        setUrlImagen(habitacion.Imagen);
        setPrecioHabitacionSeleccionadaFirebase(habitacion.Precio)

        setCheckboxContext({
          banhoPrivado: habitacion.banhoPrivado,
          banhoCompartido: habitacion.banhoCompartido,
          duchaFria: habitacion.duchaFria,
          duchaCaliente: habitacion.duchaCaliente,
          accesoTendedero: habitacion.accesoTendedero,
          internet: habitacion.internet,
          tvCable: habitacion.tvCable,
          ventilador: habitacion.ventilador,
          calefaccion: habitacion.calefaccion,
        });
      }
    });
  }, []);

  const obtenerNroHabitacion = (valor) => {
    setNroHabitacion(valor);
  };
  const obtenerArea = (valor) => {
    setArea(valor);
  };
  const obtenerSillas = (valor) => {
    setSillas(valor);
  };
  const obtenerCamas = (valor) => {
    setCamas(valor);
  };
  const obtenerArmarios = (valor) => {
    setArmarios(valor);
  };
  const obtenerMesas = (valor) => {
    setMesas(valor);
  };
  const obtenerVeladores = (valor) => {
    setVeladores(valor);
  };

  // Funcionamiento de input vertical y textarea

  const obtenerDescripcion = (e) => {
    setDescripcion(e.target.value);
  };
  const obtenerPrecio = (e) => {
    setPrecio(parseInt(e.target.value));
  };

  //Obtenemos valores desde el componente CheckboxServicios
  const [serviciosHabitacion, setServiciosHabitacion] = useState("");
  const obtenerServicios = (checkboxValues) => {
    setServiciosHabitacion(checkboxValues);
  };

  const fileInputRef = useRef(null);
  //Obtenemos y almacenamos archivo en const imagen
  const [imagen, setImagen] = useState("");

  const manejarImagen = (e) => {
    setImagen(e.target.files[0]);
    if (e.target.files[0]) {
      const reader = new FileReader();
      reader.onload = function (event) {
        setSourceImagen(event.target.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    } else {
      setSourceImagen(urlImagen);
    }
  };

  //****************************************************************************************************************
  useEffect(()=>{
    if(precioHabitacionSeleccionadaFirebase!=null && precio!=""){
      const precioNumero = parseInt(precio)
      const index = preciosHabitacionesFirebase.indexOf(precioHabitacionSeleccionadaFirebase)
      if(index!=-1){
        const preciosHabitaciones = [...preciosHabitacionesFirebase]
        preciosHabitaciones[index]=precioNumero
        setPreciosHabitacionesEnviar(preciosHabitaciones)
      }
    }
  },[precio])

  const confirmarInicioDeEnvio = () => {
    setProcesoEnvioIniciado(true);
  };

  const manejarEnvio = async (e) => {
    e.preventDefault();
    confirmarInicioDeEnvio()
    let urlImagenFirebase;
    if (imagen) {
      // Si se ha seleccionado una imagen, carga la imagen al storage y obtén la URL
      const refImagen = ref(
        storage,
        `usuarios/propietarios/${currentUser[0].uid}/propiedades/${uuidPropiedad}/habitaciones/${uuidHabitacion}/imagenesHabitacion/${uuidPropiedad}-${uuidHabitacion}-imagen1`
      ); //definimos ubicacion de imagen en storage firebase donde crearemos carpeta propiedades
      await uploadBytes(refImagen, imagen); //subimos la const imagen a la ubicacion definida en refImagen mediante la funcion uploadBytes
      //obtenemos la url de la imagen subida al storage
      urlImagenFirebase = await getDownloadURL(refImagen);
    } else {
      urlImagenFirebase = urlImagen;
    }

    const datosHabitacion = {
      NroHabitacion: nroHabitacion,
      Area: area,
      Sillas: sillas,
      Camas: camas,
      Armarios: armarios,
      Mesas: mesas,
      Veladores: veladores,
      Descripcion: descripcion,
      Precio: precio,
      Imagen: urlImagenFirebase,
    };

    //Se fusionan los datosInputs y los datos de seervicios traidos desde el componente CheckboxServicios
    const newHabitacion = { ...serviciosHabitacion, ...datosHabitacion };

    //enviar datos recolectados al database
    try {
      if(urlImagenFirebase){
        const documentoRef = doc(db, "habitaciones",idHabitacion);
        await updateDoc(documentoRef, newHabitacion);
      }
    } catch (error) {
      console.error("Error al editar habitacion:", error);
    }

    const newPropiedad = {
      preciosHabitaciones: preciosHabitacionesEnviar,
    };
    //enviar datos recolectados al database
    try {
      if(urlImagenFirebase){
        const documentoRef = doc(db, "propiedades",idPropiedad);
        await updateDoc(documentoRef, newPropiedad);
      }
    } catch (error) {
      console.error("Error al actualizar precios en propiedad:", error);
    }
    //nos direccionamos a la pagina donde estan registradas las habitaciones
    navigate(`/AgregarHabitaciones`);
  };

  return (
    <section>
      <StyledForm onSubmit={manejarEnvio}>
        <Etiqueta>Asigne un número a la habitacion y su área</Etiqueta>
        <InputHorizontalRequired
          obtenerDatos={obtenerNroHabitacion}
          $etiqueta="Habitación N°:"
          datosContext={nroHabitacion}
        />
        <InputHorizontalRequired
          obtenerDatos={obtenerArea}
          $etiqueta="Area(m2):"
          datosContext={area}
        />
        <Etiqueta>
          Describa lo mas resaltante de la habitación:
          <AreaTexto
            value={descripcion}
            onChange={obtenerDescripcion}
            maxLength="180"
            required
          />
        </Etiqueta>
        <Etiqueta>
          Seleccione los servicios con los que cuenta la habitación:
        </Etiqueta>
        <CheckboxServicios
          obtenerDatos={obtenerServicios}
          checkboxContext={checkboxContext}
        />
        <Etiqueta>
          Llene la cantidad de muebles con los que cuenta esta habitación (si no
          cuenta con ninguno, deje los espacios en cero):
        </Etiqueta>
        <InputHorizontal
          obtenerDatos={obtenerSillas}
          $etiqueta="Sillas:"
          datosContext={sillas}
        />
        <InputHorizontal
          obtenerDatos={obtenerCamas}
          $etiqueta="Camas:"
          datosContext={camas}
        />
        <InputHorizontal
          obtenerDatos={obtenerArmarios}
          $etiqueta="Armarios:"
          datosContext={armarios}
        />
        <InputHorizontal
          obtenerDatos={obtenerMesas}
          $etiqueta="Mesas:"
          datosContext={mesas}
        />
        <InputHorizontal
          obtenerDatos={obtenerVeladores}
          $etiqueta="Veladores:"
          datosContext={veladores}
        />
        <Etiqueta>Asigne el precio de la habitación (S/.)</Etiqueta>
        <Entrada value={precio} onChange={obtenerPrecio} type="number" required />
        <Etiqueta>Presione la imagen para cambiarla:</Etiqueta>
        <EntradaFile
          type="file"
          name="image"
          accept="image/*"
          //capture="environment"
          onChange={manejarImagen}
          ref={fileInputRef}
          id="inputImagen"
        />
        <StyledLabel htmlFor="inputImagen">
          <StyledImg src={sourceImagen} alt="Cambiar Fotografia de habitacion"/>
        </StyledLabel>
        <BotonSubmit $large $procesoenvioiniciado={procesoEnvioIniciado} disabled={procesoEnvioIniciado ? true : false}>{procesoEnvioIniciado ? "Guardando... ⌛⌛" : "Guardar Cambios"}</BotonSubmit>
      </StyledForm>
    </section>
  );
};

export default FormEditarHabitacion;
