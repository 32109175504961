import styled from "styled-components"
import facebook from "../../assets/iconos/facebook.svg"
import instagram from "../../assets/iconos/instagram.svg"
import whatsapp from "../../assets/iconos/whatsapp.svg"
import { colorSecundario } from "../UI/variables"

const StyledDiv = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: ${colorSecundario};
    height: 56px;
    width: 100vw;
`
const Icon = styled.img`
background-color: transparent;
    height: 24px;

`
const PiePaginaContacto = ()=>{
    return(
        <StyledDiv>
            <Icon src={facebook} alt="Logo de facebook"/>
            <Icon src={instagram} alt="Logo de instagram"/>
            <Icon src={whatsapp} alt="Logo de whatsapp"/>
        </StyledDiv>
    )
}

export default PiePaginaContacto