import styled from "styled-components";
import Boton from "../Boton";
import Titulo2 from "../Titulo2";
import { colorSecundario, colorTerciario } from "../UI/variables";
import { Link, useParams } from "react-router-dom";
import { useContext } from "react";
import { AuthContextStudent } from "../../context/AuthStudent";
import { collection, getFirestore, onSnapshot, query, where } from "firebase/firestore";
import appFirebase from "../../firebase";

const StyledDiv = styled.div`
  box-sizing: border-box;
  height: 308px;
  width: 328px;
  background-color: ${colorSecundario};
  text-align: center;
  padding: 8px;
  border: 1px solid ${colorTerciario};
  margin-top: 16px;
`;
const ContenedorDatos = styled(Link)`
  margin: 8px 0 8px 0;
  display: flex;
  justify-content: space-between;
  text-decoration: none;
`;
const Direccion = styled(Titulo2)`
  height: 48px;
  width: 296px;
  margin: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const DivBotones = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const BotonUbicacion = styled(Boton)`
  background-color: #00a4ba;
  width: 152px;
`;
const BotonVerHabitaciones = styled(Boton)`
  width: 152px;
`;

const DivPrecio = styled.div`
  display: flex;
  flex-direction: column;
  width: 308px;
  border: 1px dashed ${colorTerciario};
  margin: 0;
`;
const PrecioLetras = styled.p`
  font-size: 10px;
  font-weight: 500;
  margin: 0;
`;
const PrecioNumeros = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin: 0;
`;
const StyledImg = styled.img`
  width: 144px;
  height: 144px;
`;
const StyledP = styled.p`
  width: 144px;
  height: 144px;
  margin: 0;
  display: flex;
  align-items: center;
`;

const CardPropiedad = (props) => {
  const db = getFirestore(appFirebase);
  let { ubicacion } = useParams();
  const {setHabitaciones} = useContext(AuthContextStudent)
  //*******************************OBTENCION DE HABITACIONES DE LA PROPIEDAD********************************* */
  const obtenerHabitacionesPropiedad = ()=>{
    const q = query(collection(db, "habitaciones"), where("idPropiedad", "==", `${props.idPropiedad}`))
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const habitacionesFirebase = snapshot.docs.map((doc) => ({
        ...doc.data(),
        idHabitacion: doc.id,
      }));
      setHabitaciones(habitacionesFirebase);
    });
    return () => unsubscribe();
  }
  return (
    <section>
      <StyledDiv>
        <Direccion>{props.direccion}</Direccion>
        <ContenedorDatos onClick={obtenerHabitacionesPropiedad} to={`/Habitaciones/${props.idPropiedad}`}>
          <StyledP>{props.descripcion} </StyledP>
          <StyledImg src={props.imagen} alt="Fotografia de la propiedad"/>
        </ContenedorDatos>
        <DivPrecio>
          <PrecioLetras>Habitaciones Desde - Hasta</PrecioLetras>
          <PrecioNumeros>{`s/. ${props.precioMinimo} - s/. ${props.precioMaximo}`}</PrecioNumeros>
        </DivPrecio>
        <DivBotones>
          <BotonUbicacion to={`/TabMapa/${ubicacion}/${props.latitud}/${props.longitud}`}>Ubicacion</BotonUbicacion>
          <BotonVerHabitaciones onClick={obtenerHabitacionesPropiedad} to={`/Habitaciones/${props.idPropiedad}`}>Ver Habitaciones</BotonVerHabitaciones>
        </DivBotones>
      </StyledDiv>
    </section>
  );
};

export default CardPropiedad;
