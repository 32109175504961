import styled from "styled-components"
import Boton from "../../../components/Boton"
import { fondo } from "../../../components/UI/variables"
import Titulo2 from "../../../components/Titulo2"
import ListaHabitacionesVencidas from "../../../components/ListaHabitacionesVencidas"

const StyledSection = styled.section`
  background-color: ${fondo};
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
`
const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  height: 40px;
  width: 100%;
  align-items: center;
`
const TituloStyled= styled(Titulo2)`
  width: 100%;
  text-align: center;
`
const VerHabitacionesVencidas = ()=>{
  return(
    <StyledSection>
      <StyledHeader>
        <Boton to={`/HomeAdmin`}>Atras</Boton>
        <TituloStyled $naranja>Habitaciones Vencidas</TituloStyled>
      </StyledHeader>
        <ListaHabitacionesVencidas/>
    </StyledSection>
  )
}
export default VerHabitacionesVencidas;