import React, { useState } from "react";
import styled from "styled-components";
import { colorPrimario, textoClaro, fondo } from "../UI/variables";

const StyledLink = styled.p`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 0px 8px 0px;
  color: black;
  font-size: 14px;
  font-weight: bold;
  border: none;
  background-color: none;
  height: 40px;
  width: 328px;
  text-decoration: underline;
`;
const StyledDialog = styled.dialog`
  border-radius: 10px;
  border: 1px solid ${colorPrimario};
  align-items: center;
  width: 240px;
  height: auto;
`;
const StyledP = styled.p`
  margin: 0 0 8px 0;
  text-align: center;
`;
const BotonCerrar = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 8px 0px 8px 56px;
  color: ${textoClaro};
  font-size: 14px;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  background-color: ${colorPrimario};
  height: 32px;
  width: 128px;
  text-decoration: none;
`;

const DialogReglas = ({ reglas }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => {
    setIsOpen(false);
  };

  const abrirDialog = () => {
    setIsOpen(true);
  };

  return (
    <section>
      <StyledDialog open={isOpen} onClose={handleClose}>
        <StyledP><strong>Reglas de la casa:</strong></StyledP>
        <StyledP>
            {`${reglas}`}
        </StyledP>
        <BotonCerrar onClick={handleClose}>Cerrar</BotonCerrar>
      </StyledDialog>
      <StyledLink onClick={abrirDialog}>Toca aqui para ver las reglas de la casa!  👀</StyledLink>
    </section>
  );
};
export default DialogReglas;
